<template lang="pug">
  main#feedback
    header-red
    sidebar

    b-container
      h5 Per noi è molto importante la tua opininione per poter sempre migliorare
      h4 Come valuteresti la tua esperienza con
        img(src="/logo-black.svg" alt="logo")
        | ?
      div.d-block(style="margin: 0 auto; width: 210px")
        .vote
          .stars.big
            .star(
              v-for="i in 5"
              :class="{active: form.total >= i || tmp_stars.total >= i}"
              @click="fill_star('total', i)"
              @mouseover="tmp_stars.total = i"
              @mouseleave="tmp_stars.total = null"
            )
          span voto totale

      .votes
        b-row(v-for="vote in votes" :key="vote.key")
          b-col
            label {{ vote.key }}
          b-col
            .vote
              .stars
                .star(
                  v-for="i in 5"
                  :class="{active: form[vote.value] >= i || tmp_stars[vote.value] >= i}"
                  @click="fill_star(vote.value, i)"
                  @mouseover="tmp_stars[vote.value] = i"
                  @mouseleave="tmp_stars[vote.value] = null"
                )

        hr

        div(v-if="order && order.pizzas")
          b-row(
            v-for="pizza in order.pizzas"
            :key="pizza.name"
          )
            b-col
              label {{ pizza.name }}
            b-col
              .vote
                .stars
                  .star(
                    v-for="i in 5"
                    :class="{active: form.pizzas[pizza.pizza] >= i || tmp_stars.pizzas[pizza.pizza] >= i}"
                    @click="fill_star('pizza', i, pizza.pizza)"
                    @mouseover="tmp_stars.pizzas[pizza.pizza] = i"
                    @mouseleave="tmp_stars.pizzas[pizza.pizza] = null"
                  )

        hr

        label(style="float: left") Note
        b-form-textarea(
          rows="3" max-rows="3" v-model="form.note"
        )

        b-button(variant="primary" @click="send_feedback()") Invia

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import { mapActions } from "vuex";

export default {
  name: "Feedback",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  props: ["id"],
  data() {
    return {
      order: null,
      tmp_stars: {
        total: null,
        purchase: null,
        food: null,
        delivery: null,
        pizzas: {},
      },
      form: {
        order: null,
        total: null,
        purchase: null,
        food: null,
        delivery: null,
        pizzas: {},
        note: null,
      },
      votes: [
        { key: "Ordinazione", value: "purchase" },
        { key: "Cibo", value: "food" },
      ],
    };
  },
  async mounted() {
    const order_id = this.$route.query["id"];

    if (!order_id) {
      this.$bvToast.toast("Devi selezionare un'ordine!", {
        title: "Errore",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center",
      });
      this.relocate("/");
    }

    this.order = await this.find_order(order_id);
    if (this.order["error"]) {
      this.$bvToast.toast("Non esiste questo ordine!", {
        title: "Errore",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center",
      });
      this.relocate("/");
    }

    this.form.order = order_id;
    for (const pizza of this.order.pizzas) {
      this.$set(this.form.pizzas, pizza.pizza, null);
      this.$set(this.tmp_stars.pizzas, pizza.pizza, null);
    }

    // Add delivery vote if order has been delivered at home
    if (this.order.delivery == "home") {
      this.votes.push({ key: "Consegna", value: "delivery" });
    }
  },
  methods: {
    fill_star(key, number, pizza) {
      if (key == "total") {
        this.form.purchase = number;
        this.form.delivery = number;
        this.fill_star("food", number);
      } else if (key == "food") {
        this.form.food = number;
        for (const el of Object.keys(this.form.pizzas)) {
          this.form.pizzas[el] = number;
        }
      } else if (key == "pizza") {
        this.form.pizzas[pizza] = number;
      } else {
        this.form[key] = number;
      }
    },
    send_feedback() {
      const payload = {
        order: this.form.order,
        purchase: this.form.purchase,
        delivery: this.form.delivery,
        pizzas: [],
      };

      if (this.form.note) {
        payload.note = this.form.note;
      }

      for (const key of Object.keys(this.form.pizzas)) {
        payload.pizzas.push({
          pizza: key,
          vote: this.form.pizzas[key],
        });
      }

      this.create_feedback(payload).then((res) => {
        if (res.status != 201) {
          if (!res.data["detail"]) {
            if (res.data["purchase"]) {
              res.data["ordinazione"] = res.data["purchase"];
              delete res.data["purchase"];
            }

            if (res.data["pizzas"]) {
              delete res.data["pizzas"];
              res.data["pizze"] = ["Devi mettere un voto a tutte le pizze."];
            }

            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            this.$bvToast.toast(res.detail, {
              title: "Errore durante la creazione del feedback!",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        } else {
          this.$bvToast.toast("Arriverà presto allo staff di ConzaTu", {
            title: "Feedback aggiunto!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.relocate("/orders");
        }
      });
    },
    relocate(path) {
      setTimeout(() => {
        window.location.href = path;
      }, 1000);
    },
    ...mapActions("orders", {
      find_order: "find",
      create_feedback: "create_feedback",
    }),
  },
};
</script>
