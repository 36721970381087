import Vue from "vue";
import VueCookies from "vue-cookies";
var shajs = require("sha.js");

function get_json(name, _default) {
  let data;
  try {
    data = JSON.parse(localStorage.getItem(name));
  } catch {
    localStorage.removeItem(name);
  }

  if (data == null) {
    return _default;
  }

  return data;
}

Vue.use(VueCookies);

export default {
  namespaced: true,
  state: {
    items: get_json("cart", { pizza: [], extfood: [] }),
    delivery: Vue.$cookies.get("pizza_delivery"),
    address: get_json("cart_address"),
    note: localStorage.getItem("cart_note"),
    delivery_time: localStorage.getItem("cart_delivery_time"),
    loading: false,
  },
  getters: {
    count: (state) => {
      if (state.items) {
        const pizza_len = state.items["pizza"] ? state.items.pizza.length : 0;
        const extfood_len = state.items["extfood"]
          ? state.items.extfood.length
          : 0;

        return pizza_len + extfood_len;
      }

      return 0;
    },
    loading_cart: (state) => {
      return state.loading;
    },
    items: (state) => {
      return state.items;
    },
    points: (state) => {
      // Get "conzate" points

      let count = 0;
      for (const item of state.items["pizza"]) {
        count += item["qty"];
      }

      return count;
    },
    total: (state, getters, rootState, rootGetters) => {
      let price = 0;
      if (state.items["pizza"] && state.items.pizza.length > 0) {
        price = state.items.pizza
          .map(
            (item) =>
              item.qty *
              rootGetters["pizzas/pizza_price"](
                item.size,
                item.prices,
                item.dough
              )
          )
          .reduce((a, b) => a + b);
      }

      if (state.items["extfood"] && state.items.extfood.length > 0) {
        price += state.items.extfood
          .map((item) => item.qty * item.price)
          .reduce((a, b) => a + b);
      }

      if (getters.delivery == "home") {
        const orders_settings = rootGetters["orders/orders_settings"];

        if (Object.keys(orders_settings) != Array()) {
          if (price < parseFloat(orders_settings.delivery_price_threshold)) {
            price += parseFloat(orders_settings.delivery_price);
          }
        }
      }

      return price.toFixed(2);
    },
    delivery: (state) => {
      return state.delivery;
    },
    address: (state) => {
      return state.address;
    },
    note: (state) => {
      return state.note;
    },
    delivery_time: (state) => {
      return state.delivery_time;
    },
  },
  mutations: {
    loading_cart_status: (state, status) => {
      // When `status` is `true`, reaload the items. Else save the items in
      // localStorage
      state.loading = status;
      if (status) {
        state.items = JSON.parse(localStorage.getItem("cart"));
      } else {
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    save_new_item_pizza: (state, item) => {
      if (!state.items) {
        state.items = {};
        state.items["pizza"] = [];
      }

      // Can't add a pizza with an ingredient not available
      for (const topping of item.toppings) {
        if (!topping.ingredient.is_available) return;
      }
      delete item.toppings;

      let added = false;
      for (const i in state.items.pizza) {
        const si = state.items.pizza[i];
        if (
          si.pizza_id == item.pizza_id &&
          si["size"] == item.size &&
          si["dough"] == item.dough
        ) {
          state.items.pizza[i].qty += item.qty;
          added = true;
          break;
        }
      }

      if (!added) {
        // Save a SHA1 string of whole `item`. SHA1 is enough because there are
        // not much data in cart, so collisions are unrealistic.
        item["hash"] = new shajs("sha1")
          .update(JSON.stringify(item))
          .digest("hex");

        state.items.pizza.push(item);
      }

      // If `items["extfood"]` does not exists, create it as an empty array
      if (!state.items["extfood"]) {
        state.items["extfood"] = [];
      }
    },
    save_new_item_extfood: (state, item) => {
      if (!state.items) {
        state.items = {};
        state.items["extfood"] = [];
      } else if (!state.items["extfood"]) {
        state.items["extfood"] = [];
      }

      state.items.extfood.push(item);
    },
    remove_item_extfood: (state, food_id) => {
      let index_to_delete = -1;
      for (const i in state.items.extfood) {
        if (state.items.extfood[i].id == food_id) {
          index_to_delete = i;
          break;
        }
      }

      if (index_to_delete >= 0) state.items.extfood.splice(index_to_delete, 1);
    },
    remove_item_pizza: (state, pizza_hash) => {
      let index_to_delete = -1;
      for (const i in state.items.pizza) {
        if (state.items.pizza[i].hash == pizza_hash) {
          index_to_delete = i;
          break;
        }
      }

      if (index_to_delete >= 0) state.items.pizza.splice(index_to_delete, 1);

      // If I have no more pizza, I have to delete the extfood array
      if (state.items.pizza.length == 0) state.items.extfood = [];
    },
    duplicate_pizza_mutation: (state, pizza) => {
      // Change the pizza hash, set `qty`=1 and append it to `items`
      pizza.hash = new shajs("sha1")
        .update(JSON.stringify(pizza))
        .digest("hex");
      pizza.qty = 1;

      state.items.pizza.push(pizza);
    },
    save_delivery: (state, delivery) => {
      Vue.$cookies.set("pizza_delivery", delivery);
      state.delivery = delivery;
    },
    save_address: (state, address) => {
      localStorage.setItem("cart_address", JSON.stringify(address));
      state.address = address;
    },
    save_note: (state, note) => {
      localStorage.setItem("cart_note", note);
      state.note = note;
    },
    save_delivery_time: (state, delivery_time) => {
      localStorage.setItem("cart_delivery_time", delivery_time);
      state.delivery_time = delivery_time;
    },
  },
  actions: {
    add_item({ commit }, data) {
      commit("loading_cart_status", true);
      commit(`save_new_item_${data.type}`, data.item);
      commit("loading_cart_status", false);
    },
    remove_item({ commit }, data) {
      commit("loading_cart_status", true);
      commit(`remove_item_${data.type}`, data.item);
      commit("loading_cart_status", false);
    },
    duplicate_pizza({ commit }, pizza) {
      commit("loading_cart_status", true);
      commit("duplicate_pizza_mutation", pizza);
      commit("loading_cart_status", false);
    },
    update_cart({ commit }) {
      // with loading status as `false`, it saves the current `state.items`
      commit("loading_cart_status", false);
    },
    save_cart_delivery({ commit }, status) {
      commit("loading_cart_status", true);
      commit("save_delivery", status);
      commit("loading_cart_status", false);
    },
    save_cart_address({ commit }, address) {
      commit("loading_cart_status", true);
      commit("save_address", address);
      commit("loading_cart_status", false);
    },
    save_cart_extrainfo({ commit }, data) {
      commit("loading_cart_status", true);
      commit("save_note", data.note);
      commit("save_delivery_time", data.delivery_time);
      commit("loading_cart_status", false);
    },
    cart_clear() {
      // Remove all cart's data in the localStorage
      localStorage.removeItem("cart");
      localStorage.removeItem("cart_address");
      localStorage.removeItem("cart_note");
      localStorage.removeItem("cart_delivery_time");
    },
  },
};
