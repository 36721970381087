<template lang="pug">
  b-row
    b-col(md="7" cols="12")
      h3(:class="{big: index == 0}")
        b(v-if="index == 0") In preparazione: 
        | pizza di {{ pizza.owner }}, "{{ pizza.name }}"
      span ingredienti: {{ to_list(pizza) }}
    b-col(md="3" cols="12")
      b-button(:href="'/u/'+pizza.pizza" variant="primary") Ordinala anche tu
    b-col(md="1" cols="6")
      ._g
        strong ordinata
        span {{ pizza.times_ordered }} volte
    b-col(md="1" cols="6")
      ._g
        strong voto
        span.star {{ trunc_feedback(pizza.feedback) }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LiveRow",
  props: ["pizza", "index"],
  computed: {
    ...mapGetters("ingredients", ["to_list"]),
    ...mapGetters("orders", ["trunc_feedback"]),
  },
};
</script>
