<template lang="pug">
  main
    header-red
    sidebar(page="Home")

    b-container(style="margin-top: 50px")
      h2 Informativa e richiesta di consenso al trattamento dei dati personali del sito www.conzatu.com ai sensi del Reg.UE 2016/679

      p In ottemperanza agli obblighi previsti dal  Reg. UE 2016/679 e dal d.lgs 101/2018 a modifica ed integrazione del  d.lgs 196/2003, in materia di trattamento dei dati personali, con la presente si informa il Cliente/Visitatore che la Masseria Carminello s.r.l. con sede in Valverde (CT), alla Via dei Carminello n.21, P.iva 05164890872, in persona del legale rappresentante, di seguito “la Società”, sottoporrà a trattamento i dati personali che lo riguardano e che sono stati o che verranno comunicati alla società stessa.
      p Il trattamento dei dati personali sarà effettuato nel rispetto delle norme in vigore ed alle seguenti condizioni.
      p Si dichiara in questa sede che il suddetto regolamento non disciplina il trattamento dei dati personali relativi a persone giuridiche, in particolare di imprese dotate di personalità giuridica, compresi il nome e la forma della persona giuridica e i suoi dati di contatto.

      h3 Premesse
      p Il presente trattamento dei dati, ai sensi e per gli effetti del Reg. Ue 2016/679, è improntato ai principi di liceità, correttezza e trasparenza e viene effettuato per finalità determinate, esplicite e legittime.
      p I dati per i quali si richiede l'autorizzazione al trattamento, sono sempre adeguati, pertinenti e limitati a quanto necessario, rispetto alle finalità per le quali sono trattati, in esecuzione del principio di minimizzazione dei dati.

      h3 1.Finalità del trattamento
      p Il trattamento dei dati personali è diretto esclusivamente al raggiungimento delle seguenti finalità:
      p a) per adempiere a qualunque tipo di obbligo previsto da leggi o regolamenti vigenti;
      p b) per esigenze di tipo operativo e gestionale (a titolo esemplificativo e non esaustivo: risoluzione errori riscontrati dal Cliente/Visitatore, problematiche di accesso al profilo, individuazione delle pagine più gradite dagli utenti...)
      p c) per la registrazione degli accessi al sito web della società e l’utilizzo dei servizi prestati con il presente sito;
      p d) per esigenze di monitoraggio dell’andamento delle relazioni con i clienti/visitatori e/o dei rischi ad esse connessi, per la soddisfazione dello stesso e per migliorare tali relazioni;
      p e) per adempiere alle richieste e agli obblighi contrattuali stipulati con il Clienti/Visitatore;
      p f) per l'invio di comunicazioni pubblicitarie, promozionali – se specificamente autorizzate dal Cliente/Visitatore
      p g) per la partecipazione al programma fedeltà “Conzatù”

      h3 2.Base del trattamento
      p La base giuridica del trattamento dei dati personali del Cliente/Visitatore è il consenso, ove si richieda un'azione che non dia luogo alla stipula di un contratto (a titolo esemplificativo e non esaustivo: richiesta di informazioni); l'esecuzione di un contratto, qualora il Cliente/Visitatore abbia stipulato un accordo di natura contrattuale e giuridicamente vincolante con la Società.

      h3 3. Modalità di trattamento
      p Il trattamento dei dati potrà consistere, oltre che nella loro consultazione e raccolta, nella loro registrazione e  conservazione. A richiesta del Cliente/Visitatore essi verranno modificati, comunicati ad altro Titolare del Trattamento o cancellati. Tutte le precedenti azioni verranno effettuate sia mediante archiviazione cartacea che con l’ausilio di strumenti elettronici informatici e telematici, ivi compresa archiviazione mediante “cloud”, secondo modalità e con strumenti idonei a garantire la sicurezza e la riservatezza dei dati stessi (tra le misure di sicurezza adottate, si specifica in questa sede che tutti i dati vengono sottoposti a processo di crittografia).
      p In particolare, saranno adottate tutte le misure tecniche, informatiche, organizzative, logistiche e procedurali di sicurezza previste dal Reg. Ue 2016/679, in maniera tale che sia garantito il livello minimo di protezione dei dati previsto dalla legge.
      p Si precisa che, per le metodologie di conservazione effettuate, l'accesso ai dati è consentito alle sole persone incaricate del trattamento da parte della società per come meglio specificato più avanti.

      h3 4. Durata del trattamento
      p La durata del trattamento dei dati personali è limitata al tempo necessario per compiere l'azione richiesta dal Cliente/Visitatore che potrà, in ogni tempo, esercitare i diritti di cui all'art. 10 e ss. della presente informativa. In  ogni caso, per i dati relativi a richieste di informazioni o utilizzati ai fini di comunicazioni commerciali, verranno trattati per un periodo di 24 mesi, salvo diversa manifestazione di volontà del Cliente/Visitatore.
      br
      p La durata del trattamento per i dati relativi alla stipulazione di contratti e/o le scritture contabili, è commisurata ai termini di legge per la conservazione degli stessi e la prova della scrittura in giudizio.

      h3 5. Tipologia di dati raccolti
      p I dati raccolti dalla società, nel caso di stipula contrattuale sono quelli essenziali all'esecuzione dell'azione richiesta dal Cliente/Visitatore:
      ul
        li Nome;
        li Cognome;
        li Telefono;
        li Indirizzo di posta elettronica;
        li Indirizzo di consegna;
      p I dati raccolti ai fini della mera richiesta di contatto ed informazione che non si concretizzi nella conclusione di un contratto e per l'invio di comunicazioni marketing, se debitamente autorizzate, sono:
      ul
        li Nome;
        li Indirizzo email e/o numero di telefono;
      p I dati raccolti per la formazione account sono i seguenti:
      ul
        li Nome;
        li Cognome;
        li Indirizzo email.

      h3 6. Conferimento dati
      p Il conferimento dei dati è necessario ai fini della esecuzione dell'azione richiesta dal Cliente/Visitatore alla Società.
      p Il Cliente/Visitatore ha certamente facoltà di non concedere la detta autorizzazione e/o revocarla in qualsiasi momento tuttavia tale rifiuto potrebbe compromettere il regolare svolgimento del rapporto con la Società ed, in particolare, l'impossibilità di effettuare la prestazione dei servizi richiesti.

      h3 7.Comunicazione e diffusione dei dati
      p La comunicazione all’esterno dei dati personali raccolti per le finalità di cui al punto 1 potrà avvenire solo dove:
      p a) tale comunicazione sia obbligatoria per assicurare l'ottemperanza degli adempimenti previsti dalla legge o da altre norme vincolanti;
      p b) tale comunicazione sia obbligatoria per assicurare la corretta instaurazione o prosecuzione del rapporto instaurato con il  Cliente/Visitatore;
      p c) sia richiesta dall'Autorità nei casi previsti per legge.
      p I dati personali raccolti per il raggiungimento delle finalità sopra indicate potranno essere comunicati, per quanto di loro specifica competenza, a soggetti che hanno rapporti di collaborazione con la Società per la gestione dei sistemi informativi, logistici, di pagamento e per attività di consulenza. Relativamente ai software impegati, si comunica che i dati verranno comunicati, per esigenze gestionali a sentry.io e hotjar, in relazione ai quali, si invita l'utente a visionare le relative privacy policy.
      p Al di fuori dei casi summenzionati, i dati personali non saranno comunicati, diffusi, ceduti o comunque trasferiti a terzi per scopi illeciti o non connessi alle finalità della raccolta e, in ogni caso, senza rendere idonea informativa agli interessati e acquisirne il consenso, ove richiesto dalla legge.

      h3 8. Trasferimento dei dati all’estero
      p I dati personali comunicati dall'utente alla società non verranno trasferiti all'estero, verso Paesi o Organizzazioni internazionali non appartenenti all'Unione Europea che non garantiscono un livello di protezione adeguato, riconosciuto, ai sensi dell'art. 45 Reg. Ue 2016/679 , sulla base di una decisione di adeguatezza della Commissione UE.
      p Si informa altresì il Cliente/Visitatore che, ai fini dell'erogazione dei servizi del presente sito, mediante plug in o altri elementi informatici rilasciati da soggetti terzi, è possibile che si renda necessario il trasferimento dei dati personali verso Paesi o Organizzazioni internazionali extra UE, per cui la Commissione non abbia adottato alcuna decisione di adeguatezza ai sensi dell'art. 45 GDPR. In tal caso il trasferimento avrà luogo solo in presenza di garanzie adeguate fornite dal Paese o dall'Organizzazione destinatari, ai sensi dell'art. 46 GDPR e a condizione che gli interessati dispongano di diritti azionabili e mezzi di ricorso effettivi.
      p In mancanza di una decisione di adeguatezza della Commissione, ai sensi dell'art. 45 GDRP, o di garanzie adeguate, ai sensi dell'art. 46 GDPR, comprese le norme vincolanti d'impresa, il trasferimento transfrontaliero avrà luogo soltanto se si verifica una delle condizioni indicate dall'art. 49 GDPR .

      h3 9. Profilazione
      p Il presente sito <span style="color: red">non</span> effettua profilazione, anche mediante sistemi automatizzati, del Cliente/Visitatore.

      h3 10. Diritti dell’interessato
      p Ai sensi e per gli effetti degli art. 15 e ss. del Reg. UE 2016/679, il Cliente/Visitatore ha diritto a chiedere al titolare del trattamento, per come meglio identificato al successivo art. 12 della presente informativa privacy: l'accesso ai dati personali che lo riguardano, la rettifica o integrazione dei dati inesatti o incompleti; la cancellazione dei dati personali non più necessari, per i quali è stato revocato il consenso o per i quali non sussiste altro fondamento giuridico al trattamento (cd. Diritto all'oblio); la limitazione del trattamento degli stessi, nei casi indicati dall'art. 18 del suddetto Regolamento
      p Il Cliente/Visitatore ha inoltre il diritto di richiedere la trasmissione dei dati, in formato di uso comune e leggibile anche da dispositivo automatico, ad altro titolare del trattamento (c.d. “portabilità dei dati”); ha altresì facoltà di opporsi, in qualsiasi momento e per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che lo riguardano.
      p Le richieste del Cliente/Visitatore, in merito ai propri diritti di cui agli art. 15 e ss. Del Reg. UE 2016/679, verranno soddisfatte senza ingiustificato ritardo dal Titolare del Trattamento e comunque entro 30 giorni.
      p I diritti che precedono possono essere esercitati sia direttamente, sia tramite un incaricato, nelle forme previste dal Reg. Ue. 2016/679
      p Si rende noto in ogni caso che i  dati verranno trattati in modo da garantire la sicurezza, l'integrità e la riservatezza dei dati stessi nel rispetto delle misure organizzative, fisiche e logiche previste dalle disposizioni vigenti.

      h3 11. Diritto a proporre reclamo
      p Fatto salvo ogni eventuale ricorso amministrativo o giurisdizionale, il Cliente/Visitatore che ritenga che il trattamento che lo riguarda violi il Regolamento UE 2016/679, ha diritto di proporre reclamo all'autorità di controllo (Garante della Privacy).

      h3 12. Diritto al risarcimento e responsabilità
      p Chiunque subisca un danno materiale o immateriale, causato da una violazione del Reg. UE 2016/679 ha diritto ad ottenere il risarcimento del danno dal titolare del trattamento solo ove si dimostri che quest'ultimo non ha adempiuto agli obblighi prescritti.
      p Il titolare del trattamento è esonerato dalla responsabilità se dimostra che l'evento dannoso non gli è in alcun modo imputabile.

      h3 13. Titolare del Trattamento
      p Il titolare del trattamento è identificato nella Società che può essere contattata all'indirizzo email <a href="mailto:info@conzatu.com">info@conzatu.com</a>

      h3 14. Consenso al trattamento
      p Si ribadisce al Cliente/Visitatore che la prestazione del consenso al trattamento dei dati personali con le modalità e per le finalità sopra illustrate è facoltativa.
      p In caso di un diniego del consenso, la società non potrà trattare i dati personali con la conseguente impossibilità di effettuare la prestazione dei servizi richiesti.

      h3 15. Informazioni aggiuntive
      p Si informa altresì il Cliente/Visitatore che, tramite il sito www.conzatu.com possibile collegarsi ai canali della Società presenti sulle piattaforme di  social networks  denominate “Facebook” ed  “Instagram” appartenenti alla società Facebook Inc.
      p Per la gestione della privacy e dei dati personali da parte dei suddetti siti, si consiglia di visionare le relative privacy policy.
      p Questo sito fa anche uso dei c.d. Cookies di navigazione.
      p Per avere maggiori informazioni a riguardo, si consiglia di visionare la relativa policy (link)

      br
      p
        em Ultimo aggiornamento ottobre 2021

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Privacy",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
};
</script>

<style scoped>
h3 {
  margin-top: 50px;
}
</style>
