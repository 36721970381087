<template lang="pug">
  footer#main-footer
    b-container
      b-row
        b-col#v-logo
          img(src="/logo-vertical.png" alt="Vertical logo")
        b-col#aboutus
          h4 Su di noi
          p ConzaTu nasce da un'idea del fondatore della Corte dei medici, rinomata pizzeria nel centro storico di Catania.
        //- b-col#newsletter
        //-   b-form
        //-     b-form-group(id="input-email-newsletter")
        //-       b-input(
        //-         id="input-email-newsletter"
        //-         placeholder="indirizzo email"
        //-         type="email"
        //-       )
        //-     b-button#email-btn(variant="dark" type="submit")
        //-   em.privacy Cliccando il bottone sopra confermi di aver letto la 
        //-     a(href="/privacy-newsletter" target="_blank") privacy
      hr
      #links
        ul
          ul
            li
              h4 Contatti
            li.wa
              a(:href="'tel:' + info.whatsapp") {{ info.whatsapp }}
            li.email
              a(:href="'mailto:' + info.email") {{ info.email }}

          ul
            li
              h4 Link rapidi
            li
              a(href="/download") Scarica l'app
            li
              a(href="/live") Guarda la diretta

          ul
            li
              h4 Pizze
            li
              a(href="/pizza") Classiche
            li
              a(href="/conzatu") Conza la tua pizza


          ul
            li
              h4 Le regole sono importanti!
            li
              a(href="/rules" style="text-decoration: underline")
                | Consulta il regolamento
            li
              a(href="/franchising") Apri il tuo Franchising!
          ul
            li
              h4 Social
            .icons
              a(:href="info.facebook" target="_blank")
                img(src="@/assets/fb-circle.svg" alt="fb")
              a(:href="info.instagram" target="_blank")
                img(src="@/assets/ig-circle.svg" alt="ig")
      #copyright
        .l
          h5 Masseria carminello srl - Via Carminello, 21, 95028 Valverde (CT) - P.IVA {{ info.vat }}
          h5 Copyright &copy; 2021 - 
            a(href="/tos") Termini e condizioni 
            span - 
            a(href="/privacy") Privacy policy
        h6 Designed by 
          a(href="https://liberotratto.it" target="_blank")
            strong Liberotratto Srl
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterRed",
  computed: {
    ...mapGetters(["info"]),
  },
};
</script>
