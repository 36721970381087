<template lang="pug">
  main
    header-red
    sidebar(:mobileonly="true")

    b-container
      #tabs-login
        aside(:class="{active: tab==0}")
          validation-observer(ref="observer" v-slot="{ handleSubmit }")
            b-form(@click="tab=0" @submit.stop.prevent="handleSubmit(handleLogin)")
              h2 Accedi
              form-input(
                label="Username" v-model="forms.login.username" type="text"
                :model="forms.login.username" name="username"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
              form-input(
                label="Password" v-model="forms.login.password" type="password"
                :model="forms.login.password" name="password"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
              b-form-group
                a#forget-psw(href="/password-reset") Recupera password

              b-form-group._btn
                b-button(type="submit" variant="primary") Entra
                #fb-container
                  <div class="fb-login-button" onlogin="fbLogin()" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                #g-container
                  <div class="g-signin2" data-onsuccess="gLogin"></div>

          .login-or
            span oppure
            b-button(type="button" variant="secondary" @click="tab=1")
              | Registrati
        aside(:class="{active: tab==1}")
          validation-observer(ref="observer" v-slot="{ handleSubmit }")
            b-form(@click="tab=1" @submit.stop.prevent="handleSubmit(handleSignup)")
              h2 Registrati
              form-input(
                label="Email" v-model="forms.signup.email" type="email"
                :model="forms.signup.email" name="email"
                :rules="{required: true, email: true}"
                invalid_text="Il campo non è una email valida"
              )
              form-input(
                label="Username" v-model="forms.signup.username" type="text"
                :model="forms.signup.username" name="username"
                :rules="{required: true, min: 3, max: 15}"
                invalid_text="L'username dev'essere compreso tra i 3 e 15 caratteri"
              )
              form-input(
                label="Password" v-model="forms.signup.password1" type="password"
                :model="forms.signup.password1" name="password1"
                :rules="{required: true, min: 8}"
                invalid_text="La password dev'essere lunga minimo 8 caratteri"
              )
              form-input(
                label="Conferma password" v-model="forms.signup.password2" type="password"
                :model="forms.signup.password2" name="password2"
                :rules="{required: true, confirmed: 'password1'}"
                invalid_text="Le due password non coincidono"
              )

              b-form-group._btn
                b-button(type="submit" variant="primary") Registrati

          .login-or
            span oppure
            b-button(type="button" variant="secondary" @click="tab=0") Accedi

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import FormInput from "@/components/FormInput.vue";

export default {
  name: "Login",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "form-input": FormInput,
  },
  data() {
    return {
      tab: 0,
      forms: {
        login: {},
        signup: {},
      },
      logged_path: "/", // as described in [ch9663] this is the right url
    };
  },
  mounted() {
    if (this.$route.query["ref"]) {
      this.logged_path = this.$route.query.ref;
    }

    if (this.$store.getters["auth/is_logged"]) {
      window.location.href = this.logged_path;
    }

    if (this.$route.query["facebook_login_token"]) {
      console.log("Facebook login");
      this.handleLogin({ facebook: this.$route.query["facebook_login_token"] });
    }
    if (this.$route.query["google_login_token"]) {
      console.log("Google login");
      this.handleLogin({ google: this.$route.query["google_login_token"] });
    }
  },
  methods: {
    handleLogin(credentials) {
      console.log(credentials);
      if (!credentials) {
        credentials = this.forms.login;
      }

      this.$store
        .dispatch("auth/login", credentials, { root: true })
        .then((res) => {
          if (res.status != 200) {
            if (!res["data"]) {
              this.$bvToast.toast("", {
                title: "Errore durante il login!",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              });
            } else if (!res.data["detail"]) {
              for (const key of Object.keys(res.data)) {
                for (const message of res.data[key]) {
                  this.$bvToast.toast(message, {
                    title: key == "non_field_errors" ? "Login errato" : key,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                  });
                }
              }
            } else {
              this.$bvToast.toast(res.data.detail, {
                title: "Errore durante il login!",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              });
            }
          } else {
            this.$bvToast.toast("Sarai presto reindirizzato", {
              title: "Login effettuato!",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
            setTimeout(() => {
              window.location.href = this.logged_path;
            }, 2000);
          }
        });
    },
    handleSignup() {
      this.$store
        .dispatch("auth/signup", this.forms.signup, { root: true })
        .then((res) => {
          if (res.status != 201) {
            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            this.$bvToast.toast(
              "Un'email di conferma è stata inviata alla tua casella di posta elettronica!",
              {
                title: "Ci sei quasi!",
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );
            this.handleLogin({
              username: this.forms.signup.username,
              password: this.forms.signup.password1,
            });
          }
        });
    },
  },
};
</script>
