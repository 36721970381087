<template lang="pug">
  main#e404
    header-red
    sidebar

    b-container#text
      h1 Errore 404
      h3 La pagina che hai cercato non esiste, <br>torna in <a href="/">home</a> e riprova.

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Page404",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
};
</script>
