<template lang="pug">
  main
    header-red
    sidebar(page="Home")

    b-container(style="margin-top: 50px")
      h2 Condizioni generali di contratto
      h3 Premesso che:
      ul
        li La Masseria Carminello è una S.r.l. con sede in Valverde (Ct), alla via Carminello n.21, cap. 95028, P.iva: 05164890872, tel: 095325532, email: masseriacarminellosrl@pec.it
        li La Masseria Carminello s.r.l, di seguito denominata “Società”, si occupa, tra le altre cose, di ristorazione e somministrazione nei confronti del pubblico;
        li La Società è titolare del marchio “ConzaTu” mediante il quale esercita l'attività di pizzeria da asporto; 
        li I servizi di pizzeria da asporto vengono offerti anche attraverso il sito internet  www.conzatu.com di seguito denominato “il Sito” e la relativa Applicazione web;
        li Nei rapporti tra la Società e il Cliente si applicano le seguenti condizioni generali di contratto a norma del D.lgs 206/2005 come modificato dal d.lgs n.21/2014.
      h4 Definizioni
      p Ai fini dell'applicazione delle presenti condizioni generali di contratto si intendono:
      ul
        li Sito: il sito internet www.conzatu.com  attraverso il quale viene espletata l'attività d'impresa della Masseria Carminello s.r.l. come indicato in premessa; 
        li Cliente: il soggetto che acquista beni tramite il Sito;
        li Cliente-consumatore: il soggetto che acquista beni tramite il sito e protetto dalle normative a tutela del consumatore (D.lgs n.206/2005 e successive modifiche)

      h3 Art.1 Oggetto
      p La Società, nell'esercizio della propria attività d'impresa, offre in vendita i beni e i servizi presenti sul sito www.conzatu.com con le modalità specificate nelle presenti Condizioni Generali di Contratto.

      h3 Art.2 Beni
      p I beni e i servizi proposti in vendita e commercializzati dalla Società, tramite il sito www.conzatu.com  sono costituiti da prodotti alimentari, nello specifico pizze, che il cliente può guarnire a proprio piacimento avvantaggiandosi di un costo fisso, indipendentemente dagli ingredienti scelti. Il cliente potrò ordinare altresì bevande e dolce del giorno.

      h3  Art. 3 Conclusione del contratto
      p Il contratto si intende concluso al momento in cui l'accettazione del Cliente, trasmessa mediante l'invio dell'ordine digitale, giunge all'indirizzo elettronico della Società. Il Cliente riceverà, tramite email, la conferma della ricezione dell'ordine e dell'avvenuta conclusione del contratto.

      h3  Art. 4 Prezzi
      p I prezzi indicati nel sito vengono espressi in Euro e sono comprensivi di Iva. 
      p E' da considerarsi escluso il costo del trasporto che verrà indicato durante la procedura d'ordine e specificato al successivo art. 7.1. 
      p In ogni caso il Cliente verrà reso edotto del costo totale dei prodotti che desidera acquistare prima che questi confermi definitivamente l'ordine.

      h3  Art. 5 Fatturazione
      p La fattura deve essere richiesta espressamente al momento dell'ordine e non potrà essere richiesta ed emessa successivamente ai sensi e per gli effetti dell'art.22 Dpr n.633/1972.
      p Per consentirne alla Società l'emissione, è necessario, in sede di registrazione, fornire il codice fiscale e/o la partita Iva, unitamente al proprio indirizzo di posta elettronica certificato (PEC) e al codice sdi, nel caso del Cliente-impresa. 
      p Nel caso in cui non verranno forniti pec e/o codice SDI, la fattura elettronica verrà inviata al Sistema di Interscambio dell'Agenzia delle Entrate che provvederà alla consegna della stessa nel cassetto fiscale del Cliente.
      p Non sarà possibile comunicare il proprio codice SDI o pec successivamente alla conferma dell'ordine.
      p Nel caso in cui non venisse richiesta la fattura, ogni ordine verrà corredato dallo scontrino emesso al momento in cui verrà processato l'acquisto.

      h3 Art. 6 Modalità di pagamento
      p I metodi di pagamento accettati dalla Società sono i seguenti:
      ul
        li contanti e POS
        li Paypal
        li Stripe

      p <u>Il pagamento a mezzo contanti e p.o.s</u> verrà effettuato a cura del Cliente che provvederà a consegnare la somma relativa al carrello acquistato sul sito www.conzatu.com al soggetto incaricato della consegna dei beni da parte della Società. Il Cliente avrà altresì facoltà di effettuare il pagamento con bancomat o carta di credito per mezzo del POS a disposizione dei suddetti incaricati.
      p <u>Il pagamento con carta di credito</u>, per garantire la totale sicurezza delle transazioni, viene effettuato mediante il modulo di sicurezza di banca?, che permette di crittografare i dati immessi all'interno del sistema. 
      p Non appena verrà confermato l'ordine sul sito www.conzatu.com , il Cliente verrà automaticamente indirizzato sulla pagina web della banca e, negli appositi spazi, potrà inserire i dati richiesti (intestatario, numero, data di scadenza e codice della carta di credito utilizzata) ed autorizzare la transazione.
      p Lo scambio dei dati in questione avviene esclusivamente tra il Cliente e il sistema sicuro di pagamenti online. 
      p Masseria Carminello s.r.l. non verrà in nessun modo a conoscenza delle informazioni relative alla carta di credito del Cliente in quanto il pagamento verrà trasmesso direttamente da Banca______.
      p E' possibile acquistare i beni presenti sul sito anche tramite il sistema di pagamento denominato “Pay Pal” che permette di trasferire fondi senza condividere i dati della propria carta di credito o del proprio conto bancario. Il Cliente, tramite il “click” sull'apposito bottone “Paga con PayPal” sul Sito, verrà indirizzato al sito “PayPal” all'interno del quale dovrà inserire le proprie coordinate d'accesso e completerà il pagamento. 
      p Masseria Carminello s.r.l., anche in questo caso, non verrà a conoscenza delle informazioni relative alla carta di credito o del conto bancario del cliente in quanto il pagamento verrà direttamente processato da “PayPal”. 
      p Il pagamento tramite la piattaforma “Stripe” permette di immettere i dati della propria carta di credito nel sistema che provvederà a processare il pagamento. Si specifica che la piattaforma “Stripe” non condivide i dati completi degli strumenti di pagamento utilizzati dal Cliente con la Società. 
      p La Società, pertanto, non potrà in alcun modo essere considerata responsabile per eventuali usi indebiti o fraudolenti dei dati afferenti alle carte di credito, operati da terzi.

      h3 Art. 7 Modalità di fruizione del servizio
      p Il Cliente tramite il proprio terminale, accede al sito www.conzatu.com oppure alla relativa Applicazione web e inserisce i prodotti desiderati nel carrello e, in sede di check out, prosegue alla scelta del mezzo di pagamento tra quelli accettati dalla Società per come indicato al superiore art. 6. La consegna verrà effettuata, nel minor tempo possibile, all'indirizzo indicato in fase d'ordine dal Cliente. Si specifica che per effettuare l'ordine tramite il sito o l' applicazione web, il Cliente deve registrarsi, inserendo i dati richiesti dal sistema informatico negli appositi spazi, oppure utilizzare le proprie credenziali di accesso Google.

      h3 Art. 7.1 Spese di consegna
      p Le spese di consegna a carico del cliente, sono le seguenti:
      ul
        li €2 per ogni consegna;
        li La consegna è gratuita da una soglia di spesa di € 40,00 (euro quaranta/00), escluse eventuali spese di trasporto, buoni sconto, promozioni ecc.

      h3 Art. 8 Consegna dei beni
      p I beni acquistati, accertato il buon fine del pagamento per come indicato nel precedente art. 6, vengono senza indugio, rispettando le tempistiche dichiaratate al precedente art. 7 e infra al successivo art. 8.1, consegnati all'addetto al fine di recapitarli al Cliente.
      p La consegna dei beni segue le norme civilistiche di cui agli art. 1683 e ss. del Codice Civile.
      p Il bene si intende consegnato con il trasferimento della disponibilità materiale dello stesso al Cliente.

      h3 Art. 8.1 Tempistiche 
      p I prodotti ordinati vengono consegnati nel più breve tempo possibile tale da conservare gusto e fragranza degli stessi. Delle tempistiche di consegna il cliente viene edotto al momento dell'ordine. 
      p Nel caso di acquisti multipli, la data di evasione dell'ordine rispetterà la consegna più lunga per permettere l'effettuazione di una spedizione unica al Cliente.

      h3 Art. 8.2 Ritardi nella consegna
      p La Società dispone tutti i mezzi necessari affinchè il Cliente possa usufruire di un celere servizio di consegna dei beni acquistati sul Sito.
      p Nel caso in cui, per cause non imputabili alla Società, la consegna subisca un grave ritardo (superiore alle tre ore) il Cliente verrà informato ed egli potrà scegliere se mantenere o annullare l'ordine; in quest'ultimo caso egli verrà interamente rimborsato di quanto precedentemente versato in favore della Società

      h3 Art. 8.3 Consegna e Accettazione del prodotto
      p Il Cliente, al momento della consegna del bene da parte dell'incaricato deve verificare che siano presenti tutti i prodotti acquistati. Eventuali contestazioni vanno immediatamente esposte all'incaricato stesso. In mancanza, i beni si considerano correttamente consegnati.

      h3 Art. 9 Recesso
      p Ai sensi dell'art. 59 del Cod. cons. e poiché trattasi di prodotti freschi e deperibili, viene escluso il diritto di recesso. 

      h3 Art. 10 Copyrights
      p Tutti i contenuti presenti sul Sito, ivi comprese le proprietà intellettuali, sono riservati ed appartenenti ai rispettivi titolari.
      p E' fatto espresso divieto di modificare, distribuire, trasmettere, riprodurre, tradurre, pubblicare, concedere in licenza, trasferire o vendere qualsiasi informazione estratta dal Sito.
      p Le stampe o i download dei su indicati contenuti, fuori dai casi di archiviazione o visione personale, necessitato di un'autorizzazione scritta dalla Società.
      p Ogni marchio è proprietà dei rispettivi titolari.

      h3 Art. 11 Giurisdizione
      p Il Cliente che non ha residenza o domicilio in Italia dichiara espressamente di accettare la legge e la giurisdizione italiana per l'interpretazione, l'esecuzione e la risoluzione di eventuali controversie scaturenti dalle presenti condizioni generali di contratto.

      h3 Art. 12 Risoluzione delle controversie
      p Il Cliente consumatore, che abbia residenza in Europa, può rivolgersi, per la risoluzione extragiudiziale delle controversie con la Società, ad un organismo di mediazione indicato nella piattaforma ODR presente al seguente link http://ec.europa.eu/consumers/odr/. La Società rimane disponibile a fornire qualsiasi chiarimento ad eventuali quesiti inoltrati al proprio indirizzo email: <a href="mailto:info@conzatu.com">info@conzatu.com</a>

      h3 Art. 13 Foro competente
      p Per le controversie civili relative all'applicazione delle presenti condizioni generali di contratto è competente il giudice del luogo di residenza o domicilio del Cliente-consumatore; in tutti gli altri casi (clienti possessori di p.Iva o che non abbiano residenza o domicilio in Italia) viene eletto come foro competente quello di Catania.

      h3 Art. 14 Privacy
      p Il Cliente prende atto che il trattamento dei propri dati personali è necessario ai fini dell'esecuzione del contratto stipulato con la Società. La base giuridica del trattamento viene individuata nell'esecuzione del contratto. La Società si impegna ad utilizzare i suddetti dati personali esclusivamente ai fini dell’esecuzione delle presenti condizioni generali di  contratto, che verrà disciplinato e tutelato ai sensi del Reg. Ue 2016/679

      h3 Art. 15 Obbligo dell'acquirente
      p Il Cliente si impegna ed obbliga, al momento della conclusione della procedura d'acquisto sul Sito, a stampare e conservare le presenti condizioni generali di contratto. 
      p Il Cliente dichiara in questa sede di aver visionato e accettato le presenti condizioni generali di contratto prima della conclusione dell'acquisto.

      h3 Art. 16 Esclusioni
      p Il Cliente-consumatore prende atto che le normative del Capo I sez da I a IV del Codice del Consumo non si applicano nel caso di acquisti di valore inferiore ad € 50,00 (euro cinquanta/00).

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Tos",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
};
</script>

<style scoped>
h3 {
  margin-top: 50px;
}
</style>
