import Vue from "vue";
import VueCookies from "vue-cookies";

import addresses from "@/store/modules/addresses";

Vue.use(VueCookies);

export default {
  namespaced: true,
  state: {
    token: Vue.$cookies.get("access_token") || false,
    user: undefined,
  },
  getters: {
    access_token: (state) => {
      return state.token;
    },
    is_logged: (state) => {
      return state.token != false;
    },
    get_me: (state) => {
      return state.user;
    },
    has_verified_email: (state) => {
      if (state.user) return state.user.has_verified_email;

      return true;
    },
  },
  mutations: {
    save_access_token: (state, value) => {
      Vue.$cookies.set("access_token", value);
      state.token = value;
    },
    delete_access_token: (state) => {
      // Remove access_token and credentials from the browser data
      Vue.$cookies.remove("access_token");
      state.token = false;
      localStorage.removeItem("login");
      state.user = undefined;
    },
    save_user_info: (state, data) => {
      // Save user's informations from the endpoint `/me`, like its name, phone
      // and role
      state.user = data;
    },
  },
  actions: {
    check_access_token({ commit }, data) {
      // Check if response of login (or signup) has the access token. If yes,
      // save it and also save the login credentials
      if (data.res["access_token"]) {
        // save access_token
        commit("save_access_token", data.res.access_token);

        // save login credentials for later
        localStorage.setItem("login", JSON.stringify(data.credentials));
      }
    },
    async keep_access({ commit, getters, dispatch, rootState }) {
      // Check if a logged user has a valid access_token. It could be expired
      // so, in that case, make a new login with saved login credentials in the
      // LocalStorage. If there's nothing there, continue with the logout!
      // Returns the `access_token`
      let status = true;

      await fetch(`${rootState.api}/profiles/me/`, {
        headers: { Authorization: `Bearer ${getters.access_token}` },
      }).then(async (response) => {
        if ((await response.status) != 200) {
          status = false;
        } else {
          commit("save_user_info", await response.json());
        }
      });

      // if `status` is false, it means that access_token is expired
      if (!status) {
        const credentials = localStorage.getItem("login");
        // if it doesn't have login credentials saved, how it could be make a
        // login? So remove access token and continue
        if (!credentials) {
          commit("delete_access_token");
          return null;
        } else {
          // make a new login with the saved credentials and, if response has a
          // `detail` key, it means that login went wrong, so remove credentials
          // and access token
          await dispatch("login", JSON.parse(credentials)).then(
            async (response) => {
              if (response["detail"]) {
                commit("delete_access_token");
                return null;
              }
            }
          );
        }
      }

      return getters.access_token;
    },
    async login({ commit, dispatch, rootState }, credentials) {
      // Make the login and then save the credentials. It uses `rootState`
      // because it needs the root store module. It uses `dispatch` because it
      // needs to call a different action.
      // It returns the response in JSON format o status in case of response
      // error
      commit("loading_status", true, { root: true });
      let res = { status: 0, data: null };

      let url = `${rootState.api}/auth/login/`;
      if (credentials["facebook"]) {
        url = `${rootState.api}/auth/facebook/`;
        credentials = { access_token: credentials["facebook"] };
      }
      if (credentials["google"]) {
        url = `${rootState.api}/auth/google/`;
        credentials = { access_token: credentials["google"] };
      }

      await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = await response.status;
          dispatch("check_access_token", { res: res.data, credentials });
          if (res.status == 200) {
            // This is usefull if the login is called by `keepAccess`
            commit("save_user_info", res.data);
          } else {
            commit("delete_access_token");
          }
        })
        .catch((e) => {
          res.status = e.status;
        });

      commit("loading_status", false, { root: true });

      return res;
    },
    async signup({ commit, rootState }, credentials) {
      // Make a signup request and then returns a dictionary with response
      // status and response data
      commit("loading_status", true, { root: true });
      let res = { status: 0, data: null };
      await fetch(`${rootState.api}/auth/registration/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch((e) => {
          res.status = e.status;
        });

      commit("loading_status", false, { root: true });

      return res;
    },
    async edit_user_info({ commit, getters, rootState }, payload) {
      // Save new user information
      commit("loading_status", true, { root: true });

      let res = false;

      await fetch(`${rootState.api}/profiles/me/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.access_token}`,
        },
        body: JSON.stringify(payload),
      }).then(async (response) => {
        if ((await response.status) == 200) {
          commit("save_user_info", await response.json());
          res = true;
        }
      });

      commit("loading_status", false, { root: true });
      return res;
    },
    async confirm_email({ commit, rootState }, key) {
      commit("loading_status", true, { root: true });

      let res = false;

      await fetch(`${rootState.api}/auth/registration/verify-email/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key }),
      }).then(async (response) => {
        if (response.status == 200) {
          res = true;
        }
      });

      commit("loading_status", false, { root: true });
      return res;
    },
    async change_password({ commit, getters, rootState }, payload) {
      // Save new password
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      await fetch(`${rootState.api}/auth/password/change/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.access_token}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch((e) => {
          res.status = e.status;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async reset_password({ commit, rootState }, data) {
      // Request to change the password
      commit("loading_status", true, { root: true });

      let res = { status: 0, data: {} };

      const props = data.props;
      const payload = data.form;

      let url = `${rootState.api}/auth/password/reset/`;
      if (props.uid && props.token) {
        payload.uid = props.uid;
        payload.token = props.token;
        url += "confirm/";
      }
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.data = await response.json();
          res.status = await response.status;
        })
        .catch((e) => {
          res.status = e.status;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async resend_email_verification({ commit, getters, rootState }) {
      commit("loading_status", true, { root: true });

      let response_status;

      await fetch(`${rootState.api}/auth/resend-email-verification/`, {
        method: "POST",
        headers: { Authorization: `Bearer ${getters.access_token}` },
      })
        .then(async (response) => {
          response_status = response.status;
        })
        .catch((e) => {
          response_status = e.status;
        });

      commit("loading_status", false, { root: true });
      return response_status;
    },
    logout({ commit, dispatch }) {
      try {
        // eslint-disable-next-line
        fbLogout();
      } catch (e) {
        console.error(e);
      }
      try {
        // eslint-disable-next-line
        gLogout();
      } catch (e) {
        console.error(e);
      }

      commit("delete_access_token");
      dispatch("cart/cart_clear", null, { root: true });
      dispatch("rewards/rewards_clear", null, { root: true });
    },
  },
  modules: {
    addresses,
  },
};
