<template lang="pug">
  b-container#order-done
    .d-flex.info
      div
        h1 Ordine effettuato!
      div
        h4 n.ordine
        h3.id {{ order.id }}

      div
        h4 consegna
        h3(v-if="order.delivery_time")
          | {{ order.created | moment("DD/MM/YYYY") }} {{ order.delivery_time.substr(0, 5) }}

    .d-flex.status(v-if="order.status != null")
      .d-flex(v-if="order.status == 1")
        ._row
          .circle
          .line
          span ordine annullato!
      .d-flex(v-else)
        ._row
          .circle
          .line
          span(v-if="order.payment") {{ make_title() }}
          span(v-else-if="order.is_payment_on_delivery") Pagamento alla consegna
          span(v-else)
            b-button(@click="pay()" variant="primary" style="width: 235px;")
              | Paga adesso
        ._row(:class="{opacity: (!order.payment && !order.is_payment_on_delivery)}")
          .circle
          .line
          span ordine confermato
        ._row(:class="{opacity: order.status < 2}")
          .circle
          .line
          span stiamo conzando le tue pizze!
        ._row(:class="{opacity: order.status < 3}")
          .circle
          .line
          span(v-if="order.delivery == 'take'") pronto per il ritiro
          span(v-else) in consegna
        ._row(:class="{opacity: order.status < 4}")
          .circle
          .line
          span(v-if="order.delivery == 'take'") ritirato
          span(v-else) consegnata

    .feedback(v-if="order.status == 4")
      .l
        span.d-block Lascia un feedback!
        b-button(
          variant="primary"
          :href="'/feedback?id='+order.id"
        ) Valuta
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "OrderSingle",
  props: ["id"],
  data() {
    return {
      order: {},
    };
  },
  async mounted() {
    this.order = await this.find(this.$props.id);

    if (!this.order) window.location.href = "/orders";
  },
  methods: {
    pay() {
      this.pay_order(this.id).then(async (response) => {
        switch (response.status) {
          case "CREATED":
            window.location.href = response.links[1].href;
            break;
          case "APPROVED":
            window.location.href =
              "/cart/pay-confirm?token=" + response.paypal_id;
            break;
          default:
            this.$bvToast.toast(
              "Pagamento non valido, contatta un amministratore per capirne di più",
              {
                title: "Non è possibile pagare quest'ordine",
                variant: "danger",
                solild: true,
                toaster: "b-toaster-top-center",
              }
            );
        }
      });
    },
    make_title() {
      if (this.order.payment) {
        let msg = "Pagato ";
        if (this.order.payment.is_online) msg += "online";
        else msg += "in presenza";
        msg += " in data ";
        msg += this.$moment(this.order.payment.created).format(
          "DD/MM/YYYY HH:mm"
        );
        return msg;
      }
      return "";
    },
    ...mapActions("orders", ["find", "pay_order"]),
  },
};
</script>
