<template lang="pug">
  div
    new-address(
      :form="form_address"
      ref="new-address"
      :hidden="handle_edit_address"
    )

    b-modal(
      ref="address-list" hide-footer header-close-content="" size="lg"
      id="address-list"
    )
      #account
        ul#addresses
          b-button.center(@click="handle_address_modal()") Aggiungi nuovo indirizzo
          li(
            v-for="address in addresses.results"
            :key="address.id"
            :class="{active: address.id == form.address.id}"
          )
            b-card
              .actions
                b-row.mb-0
                  b-col
                    b-button(@click="select_address_for_ship(address)")
                      | Seleziona
                  b-col
                    img(
                      src="@/assets/star-red.svg"
                      title="Indirizzo di default"
                      v-if="address.is_default"
                    )
                    img(
                      src="@/assets/star-black.svg"
                      @click="make_default(address.id)"
                      title="Clicca e rendi questo indirizzo il default dalla prossima volta"
                      v-else
                    )

              h4 {{ address.name }}
              span {{ serialize_address(address) }} <br>
              span {{ address.cellular }}
              b-badge(pill variant="success" v-if="address.is_delivery_available")
                | disponibile al domicilio

    validation-observer(ref="observer" v-slot="{ handleSubmit }")
      section
        h4 Riepilogo ordine
        h5 Pizze
        .extfood
          b-card
            p(v-for="item in items.pizza" :key="JSON.stringify(item)")
              | {{ item.qty }} <b>{{ item.pizza_name }}</b> - dimensione: {{ size_options[item.size] }} 
              em(v-if="item.dough != 1") - note: {{ dough_name(item.dough) }} 
              span - €{{ float_precision(pizza_price(item.size, item.prices, item.dough)*item.qty) }}
        br

        h5 Extra
        .extfood
          b-card
            p(v-for="item in items.extfood" :key="item.id")
              | {{ item.qty }} <b>{{ item.name }}</b> 
              span - €{{ float_precision(item.price*item.qty) }}

        br
        h5 Premi scelti
        .extfood
          b-card
            p(
              v-for="item in Object.values(reward_extoods)"
            )
              b(v-if="serialized_rewards[reward_id]")
                | {{ serialized_rewards[reward_id][item].name }}
            p(v-for="item in reward_pizzas[reward_id]")
              | 1 <b>{{ item.pizza_name }}</b> - dimensione: {{ item.size_text }}

            p ------
            p Punti attuali: {{ float_precision(points.active) }}
            p Punti spesi: {{ reward_price() }}
            p Punti dopo l'ordine: {{ new_points() }}
      b-form.delivery(@submit.stop.prevent="handleSubmit(save_step)")
        b-form-group(v-slot="{ ariaDescribedby }")
          b-row
            b-col
              b-form-radio(
                v-model="form.delivery"
                :aria-describedby="ariaDescribedby"
                name="delivery"
                value="take"
                button
                button-variant="outline-secondary"
                @change="set_delivery()"
              )
                img.radio-img.w(src="@/assets/store-w.png" alt="store")
                img.radio-img.b(src="@/assets/store.png" alt="store")
                div
                  h4 Ritiro in pizzeria
                  p Via Umberto I, 109, Catania
            b-col
              b-form-radio(
                v-model="form.delivery"
                :aria-describedby="ariaDescribedby"
                name="delivery"
                value="home"
                button
                button-variant="outline-secondary"
                @change="set_delivery()"
                class="home-radio"
              )
                img.radio-img.w(src="@/assets/home-w.png" alt="home")
                img.radio-img.b(src="@/assets/home.png" alt="home")
                div
                  h4 Domicilio
              div(v-if="form.delivery == 'home' && addresses")
                div(v-if="addresses.results && addresses.results.length == 0")
                  form-input(
                    label="Nome" v-model="form.address.name" type="text"
                    :model="form.address.name" name="name"
                    :rules="{required: true}"
                    invalid_text="Il campo è obbligatorio"
                  )
                  fieldset.form-group(v-if="!form.address.street")
                    vue-google-autocomplete(
                      id="map"
                      classname="form-control"
                      placeholder="indirizzo"
                      @placechanged="get_address_data"
                    )
                  fieldset.form-group(v-else style="margin-bottom:0")
                    form-input(
                      label="Via" v-model="form.address.street" type="text"
                      :model="form.address.street" name="street"
                      :rules="{required: true}"
                      invalid_text="Il campo è obbligatorio"
                    )
                    form-input(
                      label="Città" v-model="form.address.city" type="text"
                      :model="form.address.city" name="city"
                      :rules="{required: true}"
                      invalid_text="Il campo è obbligatorio"
                    )
                    form-input(
                      label="Provincia" v-model="form.address.province" type="text"
                      :model="form.address.province" name="province"
                      :rules="{required: true}"
                      invalid_text="Il campo è obbligatorio"
                    )
                    form-input(
                      label="CAP" v-model="form.address.postcode" type="text"
                      :model="form.address.postcode" name="postcode"
                      :rules="{required: true}"
                      invalid_text="Il campo è obbligatorio"
                    )
                  form-input(
                    label="Cellulare" v-model="form.address.cellular" type="text"
                    :model="form.address.cellular" name="cellular"
                    :rules="{required: true}"
                    invalid_text="Il campo è obbligatorio"
                  )
                  span.small-label Dal prossimo ordine questo sarà il tuo indirizzo di default
                div(v-else)
                  p(v-if="addresses.results") L'indirizzo di consegna è: 
                    em
                      b {{ serialize_address(form.address) }} 
                      | a 
                      b {{ form.address.name }}
                  span.small-label 
                    | Vuoi cambiare l'indirizzo di consegna o vuoi aggiungerne un altro?
                    b-button(variant="primary" @click="handle_edit_address()") 
                      | Clicca qui
          b-row
            b-col
              label="Note"
              form-input(
                label="Note" v-model="form.note" type="textarea"
                :model="form.note" name="note"
              )

            b-col
              label Quando
              form-input(
                label="" v-model="form.delivery_time" type="time"
                :model="form.delivery_time" name="delivery_time"
                invalid_text="La data di ritiro/consegna dev'essere tra le 18:20 e le 23:59"
                :rules="{required: true, delivery: true}"
              )
              b-button(@click="delivery_now()") Prima possibile

</template>

<script>
import FormInput from "@/components/FormInput.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import NewAddress from "@/components/NewAddress.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartShipping",
  components: {
    "form-input": FormInput,
    VueGoogleAutocomplete,
    "new-address": NewAddress,
  },
  data() {
    return {
      form: {
        delivery: this.$cookies.get("pizza_delivery"),
        address: {},
        note: this.$store.getters["cart/note"],
        delivery_time: this.$store.getters["cart/delivery_time"],
      },
      form_address: {},
      size_options: {},
    };
  },
  computed: {
    ...mapGetters("pizzas", ["pizza_price", "dough_name"]),
    ...mapGetters("cart", ["items"]),
    ...mapGetters("cart", { cart_points: "points", cart_address: "address" }),
    ...mapGetters("rewards", [
      "available_rewards",
      "points",
      "serialized_rewards",
      "extra_today_points",
    ]),
    ...mapGetters("rewards", {
      reward_extoods: "my_extfoods",
      reward_id: "my_reward_id",
      reward_pizzas: "my_reward_pizzas",
    }),
    ...mapGetters("auth/addresses", ["addresses", "serialize_address"]),
  },
  async mounted() {
    this.$store.dispatch("auth/addresses/get_addresses").then((addresses) => {
      if (this.cart_address) this.form.address = { ...this.cart_address };
      else if (addresses.length > 0) this.form.address = { ...addresses[0] };
    });
    this.$store.dispatch("pizzas/get_sizes").then((result) => {
      for (const x of result) {
        this.$set(this.size_options, x.id, x.name);
      }
    });
  },
  methods: {
    float_precision(price) {
      if (!price) return 0;

      return price.toFixed(2);
    },
    new_points() {
      return this.float_precision(
        this.points.active -
          this.reward_price() +
          this.cart_points * this.extra_today_points
      );
    },
    reward_price() {
      if (this.reward_id) {
        for (const reward of this.available_rewards) {
          if (reward.id == this.reward_id) return reward.cost;
        }
      }
      return 0;
    },
    handle_address_modal() {
      this.$refs["address-list"].hide();
      this.$refs["new-address"].$refs["modal"].show();
    },
    handle_edit_address() {
      this.$refs["address-list"].show();
    },
    select_address_for_ship(address) {
      this.form.address = address;
      this.save_cart_address(address);
      this.$refs["address-list"].hide();
    },
    set_delivery() {
      this.save_cart_delivery(this.form.delivery);
    },
    delivery_now() {
      const now = this.$moment().format("HH:mm");
      const now_split_str = now.split(":");

      if (now_split_str[0] < 18) this.form.delivery_time = "18:20";
      else if (now_split_str[0] == 18 && now_split_str[1] < 20)
        this.form.delivery_time = "18:20";
      else this.form.delivery_time = now;
    },
    get_address_data(data, result, id) {
      console.log(id);
      const address = {
        name: this.form.address.name,
        cellular: this.form.address.cellular,
      };

      address.street = `${data.route} ${data.street_number ?? ""}`;
      address.city = data.locality;
      if (result.address_components[4].short_name.length == 2)
        address.province = result.address_components[4].short_name;
      address.postcode = data.postal_code;

      this.$set(this.form, "address", address);
    },
    async save_step() {
      const is_valid = await this.$refs.observer.validate();
      if (is_valid) {
        const fd = this.form.delivery;
        if (["take", "home"].indexOf(fd) < 0) {
          this.$bvToast.toast("Devi selezionare un punto di ritiro", {
            title: "Ritiro in pizzeria o domicilio",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });

          return;
        }

        if (fd == "home") {
          for (const key of ["street", "city", "province", "postcode"]) {
            if (!this.form.address[key]) {
              this.$bvToast.toast(
                "Hai inserito un indirizzo di domicilio non valido",
                {
                  title: "Errore nell'indirizzo",
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                }
              );

              return;
            }
          }

          if (this.addresses.results.length == 0) {
            await this.new_address(this.form.address).then(async (response) => {
              if (response.status == 201) {
                this.form.address = response.data;
              } else {
                this.$bvToast.toast(
                  "Hai inserito un indirizzo di domicilio non valido. Controlla anche il numero di cellulare se valido.",
                  {
                    title: "Errore nell'indirizzo",
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                  }
                );
              }
            });
            if (this.addresses.results.length == 0) {
              return;
            }
          }

          if (this.form.address["province"].toUpperCase() != "CT") {
            this.$bvToast.toast("Consegniamo solo su Catania al momento", {
              title: "Errore nell'indirizzo",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });

            return;
          }

          if (!this.form.address.is_delivery_available) {
            this.$bvToast.toast(
              "Attualmente non consegniamo a quest'indirizzo",
              {
                title: "Errore nell'indirizzo",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );

            return;
          }
        }

        this.save_cart_address(this.form.address);
        this.save_cart_extrainfo({
          note: this.form.note ?? "",
          delivery_time: this.form.delivery_time,
        });

        window.location.href = "/cart/pay";
      }
    },
    ...mapActions("auth/addresses", ["make_default", "new_address"]),
    ...mapActions("cart", [
      "save_cart_address",
      "save_cart_delivery",
      "save_cart_extrainfo",
    ]),
  },
};
</script>
<style>
#address-list___BV_modal_outer_ > .modal.show .modal-dialog {
  top: 0 !important;
}
</style>
