export default {
  namespaced: true,
  state: {
    slider: [],
    blocks: []
  },
  getters: {
    slider: state => {
      return state.slider;
    },
    blocks: state => {
      return state.blocks;
    }
  },
  mutations: {
    save_slider: (state, slider) => {
      state.slider = slider;
    },
    save_blocks: (state, blocks) => {
      state.blocks = blocks;
    }
  },
  actions: {
    async get_promotions({ rootState }, type) {
      let data;
      await fetch(`${rootState.api}/promotions/public/?limit=1000&type=${type}`)
        .then(async response => {
          data = await response.json();
        })
        .catch(() => {
          data = [];
        });

      return data.results;
    },
    async get_slider({ commit, dispatch }) {
      await dispatch("get_promotions", "slider").then(response => {
        commit("save_slider", response);
      });
    },
    async get_blocks({ commit, dispatch }) {
      await dispatch("get_promotions", "block").then(response => {
        commit("save_blocks", response);
      });
    }
  }
};
