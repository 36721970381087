export default {
  namespaced: true,
  state: {
    addresses: [],
  },
  getters: {
    addresses: (state) => {
      return state.addresses;
    },
    serialize_address: () => (address) => {
      let str = address.street + ", ";
      str += address.city + " ";
      str += `(${address.province})`;

      return str;
    },
  },
  mutations: {
    save_addresses: (state, list) => {
      state.addresses = list;
    },
    extend_addresses: (state, list) => {
      state.addresses.next = list.next;
      state.addresses.results.push(...list.results);
    },
    append_address: (state, address) => {
      state.addresses["results"].splice(1, 0, address);
      state.addresses.count++;
    },
    save_address: (state, data) => {
      let index = 0;
      for (const address of state.addresses.results) {
        if (address.id == data.id) {
          break;
        }

        index++;
      }

      state.addresses.results[index] = data;
    },
  },
  actions: {
    async get_addresses({ commit, rootGetters, rootState }, endpoint) {
      let data;
      let url;
      if (endpoint) {
        // Chrome raises error for mix of HTTP and HTTPS requests
        url = endpoint.replace("http://", "https://");
      } else {
        url = `${rootState.api}/profiles/me/addresses/?limit=30`;
      }

      await fetch(url, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      }).then(async (response) => {
        data = await response.json();
      });

      if (endpoint) {
        commit("extend_addresses", data);
      } else {
        commit("save_addresses", data);
      }

      return data.results;
    },
    async new_address({ commit, rootGetters, rootState }, form) {
      // Save new address
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/profiles/me/addresses/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(form),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
          if (res.status == 201) {
            commit("append_address", res.data);
          }
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async edit_address({ commit, rootGetters, rootState }, form) {
      // Edit an address
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/profiles/me/addresses/${form.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(form),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
          commit("save_address", res.data);
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async remove_address(
      { commit, dispatch, rootGetters, rootState },
      address_id
    ) {
      // Remove an address
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/profiles/me/addresses/${address_id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      })
        .then(async (response) => {
          res.status = await response.status;

          if (res.status == 204) {
            dispatch("get_addresses");
          } else {
            res.data = await response.json();
          }
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });

      return res;
    },
    async make_default(
      { commit, dispatch, rootGetters, rootState },
      address_id
    ) {
      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/profiles/me/addresses/${address_id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: '{"is_default": true}',
      })
        .then(async (response) => {
          if ((await response.status) == 200) {
            dispatch("get_addresses");
          }
        })
        .catch((e) => {
          alert(e);
        });

      commit("loading_status", false, { root: true });
    },
  },
};
