<template lang="pug">
  slick#pizzaslick(ref="pizzaslick" :options="slick_options")
    .pizza(v-for="pizza in pizzas" :key="pizza.id")
      a(:href="'/u/'+pizza.url")
        img(:src="media_base+'/pizzas/'+pizza.image" :alt="pizza.name")
      .text
        h5 {{ pizza.name }}
        p(v-html="to_reduced_list(pizza, 5)")
        span €{{ pizza.prices[0].price }}
</template>

<script>
import Slick from "vue-slick";

import { mapGetters } from "vuex";

export default {
  name: "PizzaSlider",
  components: { Slick },
  computed: {
    ...mapGetters("ingredients", ["to_reduced_list"]),
  },
  data() {
    return {
      pizzas: [],
      slick_options: {
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 5,
        arrows: false,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      media_base: process.env.VUE_APP_MEDIA_URL,
    };
  },
  async mounted() {
    const api = this.$store.getters.api_endpoint;
    await fetch(`${api}/pizzas/all/?limit=10&order_by_time_ordered`).then(
      async (response) => {
        const data = await response.json();
        this.pizzas = data.results;
      }
    );
  },
  watch: {
    pizzas: function () {
      let currIndex = this.$refs.pizzaslick.currentSlide();

      this.$refs.pizzaslick.destroy();
      this.$nextTick(() => {
        this.$refs.pizzaslick.create();
        this.$refs.pizzaslick.goTo(currIndex, true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/slick-carousel/slick/slick.css";
</style>
