<template lang="pug">
  #pizza
    img(
      :src="'/pizzas/'+ingredient.icon+'.png'" alt=""
      v-for="ingredient in ingredients" :key="ingredient.id"
      :class="['i'+ingredient.level, {'top': ingredient.is_after_baking}]"
    )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PizzaImage",
  computed: {
    ...mapGetters("pizzas", ["ingredients"]),
  },
};
</script>

<style lang="sass" scoped>
#pizza img
  position: absolute
  width: 100%
  &.i1
    z-index: 1
  &.i2
    z-index: 2
  &.i3
    z-index: 3
  &.i4
    z-index: 4
  &.i5
    z-index: 5
  &.top
    z-index: 6
</style>
