<template lang="pug">
  main
    header-red(:class="{'blur': blur}")
    sidebar(page="Pizza" :class="{'blur': blur}")

    b-modal(
      ref="pizza-share" hide-footer header-close-content=""
      @hidden="() => this.$store.dispatch('blur_status')" size="lg"
    )
      share-box-modal(:url="get_url()")

    b-container(:class="{'blur': blur}")
      #pizza-details
        .header
          a.back(href="/pizza")
            span torna alle pizze

        b-alert(show variant="warning" v-if="!is_available()")
          h3.alert-heading Pizza non disponibile all'acquisto
          p Attualmente non tutti gli ingredienti di questa pizza sono disponibili
          hr
          p.mb-0 Modifica questa pizza ed acquistala 
            a(href="#" @click="edit_ingredients()") da qui
            | .
        main
          aside
            .details-top.desktop
              .l
                strong ordinata
                span {{ details.times_ordered || 0 }} volte
              .r
                strong voto
                span.stars {{ trunc_feedback(details.feedback) }}

            pizza-gallery-slider(:image="details.image")
          aside#info
            h1 {{ details.name }}
              span.price(v-if="!(pizza_to_select && pizza_to_select.pizza_size)")
                | €{{ pizza_price(form.size, false, form.dough) }}
            .ingredients
              p(v-html="to_list(details)")
            div(v-if="!(pizza_to_select && pizza_to_select.pizza_size)")
              span.price-mobile €{{ pizza_price(form.size, false, form.dough) }}
              .radios
                b-form-group(label="Impasto:" v-if="dough_options.length > 1")
                  b-form-radio-group(
                    id="btn-radios-dough"
                    v-model="form.dough"
                    :options="dough_options"
                    name="dough"
                    button-variant="outline-primary"
                    buttons
                  )
                b-form-group(label="Grandezza:" v-if="size_options.length > 1")
                  b-form-radio-group(
                    id="btn-radios-size"
                    v-model="form.size"
                    :options="size_options"
                    name="size"
                    button-variant="outline-primary"
                    buttons
                  )
                b-form-group.qta(label="Quantità:")
                  b-button(variant="secondary" @click="chg_qta(-1)") -
                  b-form-input(
                    type="number" min="1" name="quantity"
                    v-model="form.quantity"
                  )
                  b-button(variant="secondary" @click="chg_qta(1)") +
              .share-text(@click="share_pizza()" v-if="details.is_visible")
                | Condividi
              .author
                h4 Creata da 
                  a(:href="'/u/'+details.owner_username") {{ details.owner }}
              div#comment_owner(v-if="am_i_the_owner")
                .comment(v-if="details.description && !show_edit_descr")
                  | {{ details.description }}
                .comment.grey(v-else-if="!show_edit_descr")
                  | Scrivi qui cosa ti ha ispirato
                b-input(
                  v-else-if="show_edit_descr"
                  v-model="details.new_description"
                  placeholder="Scrivi qui cosa ti ha ispirato"
                  autofocus
                )

                b-button.edit(
                  v-if="!show_edit_descr"
                  @click="edit_pizza_description_input"
                  variant="primary"
                ) Modifica
                b-button.save(
                  v-else
                  @click="edit_pizza_description"
                  variant="primary"
                ) Salva
              div(v-else)
                .comment(v-if="details.description") {{ details.description }}
              .cart
                span.text-not-available(v-if="!is_available()")
                  | Attualmente non tutti gli ingredienti sono disponibili
                span.text-not-available(v-if="!are_orders_enabled")
                  | Attualmente il negozio è chiuso
                b-overlay(
                  :show="loading_cart" rounded spinnel-small
                  class="d-inline-block"
                )
                  b-button(variant="primary" @click="add_item()" :disabled="!is_available() || !are_orders_enabled")
                    | Aggiungi al carrello
                b-button(variant="outline-secondary" @click="edit_ingredients()")
                  | Modifica ingredienti
            div(v-else)
              .cart
                .reward
                  b-button.gift(
                    @click="add_gift()"
                  )
                    | Seleziona come premio
            .details-top.mobile
              .l
                strong ordinata
                span {{ details.times_ordered || 0 }} volte
              .r
                span.stars {{ trunc_feedback(details.feedback) }}
                strong voto

    b-container(:class="{'blur': blur}")
      hr
      boxes-3

    footer-red(:class="{'blur': blur}")
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import PizzaGallerySlider from "@/components/PizzaGallerySlider.vue";
import Boxes3 from "@/components/Boxes3.vue";
import ShareBoxModal from "@/components/ShareBoxModal.vue";

import { mapGetters } from "vuex";

export default {
  name: "PizzaList",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "pizza-gallery-slider": PizzaGallerySlider,
    "boxes-3": Boxes3,
    "share-box-modal": ShareBoxModal,
  },
  props: ["username", "pizza"],
  data() {
    return {
      size_options: [],
      form: {
        dough: 1,
        quantity: 1,
      },
      show_edit_descr: false,
    };
  },
  computed: {
    blur: function () {
      return this.$store.getters.have_to_blur;
    },
    ...mapGetters("cart", ["loading_cart"]),
    ...mapGetters("pizzas", [
      "details",
      "dough_options",
      "am_i_the_owner",
      "pizza_price",
    ]),
    ...mapGetters("ingredients", ["to_list"]),
    ...mapGetters("orders", ["are_orders_enabled", "trunc_feedback"]),
    ...mapGetters("rewards", ["pizza_to_select"]),
  },
  methods: {
    share_pizza() {
      this.$store.dispatch("blur_status");
      this.$refs["pizza-share"].show();
    },
    get_url() {
      return window.location.href;
    },
    is_available() {
      if (this.details.toppings) {
        for (const topping of this.details.toppings) {
          if (!topping.ingredient.is_available) return false;
        }
        return true;
      }
      return false;
    },
    edit_ingredients() {
      let url = "/conzatu?ingredients=edit";
      for (const x of this.details.toppings) {
        url += `&id[]=${x.ingredient.id}`;
        if (x.after_baking) url += `&ab[]=${x.ingredient.id}`;
        if (x.abundant) url += `&a[]=${x.ingredient.id}`;
      }
      window.location.href = url;
    },
    edit_pizza_description_input() {
      this.show_edit_descr = !this.show_edit_descr;
    },
    edit_pizza_description() {
      const data = {
        id: this.details.id,
        payload: {
          description: this.details.new_description,
        },
      };

      this.$store.dispatch("pizzas/edit", data).then((res) => {
        if (res.status == 200) {
          this.$bvToast.toast("Descrizione salvata", {
            title: "Successo!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.details.description = this.details.new_description;
        } else {
          this.$bvToast.toast(res["detail"] ? res.detail : "Riprova", {
            title: "Errore durante il salvataggio!",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.details.new_description = this.details.description;
        }
      });
      this.show_edit_descr = false;
    },
    chg_qta(value) {
      let new_value = parseInt(this.form.quantity) + value;
      if (!new_value) {
        new_value = 1;
      }

      this.$set(this.$data.form, "quantity", new_value);
    },
    add_gift() {
      const item = {
        item_id: this.pizza_to_select.id,
        pizza: this.details.url,
        pizza_name: this.details.name,
        image: this.details.image,
        size: this.pizza_to_select.pizza_size,
        toppings: this.details.toppings,
      };

      let size = "";
      for (const option of this.size_options) {
        if (option.value == item.size) size = option.text;
      }

      if (size == "") {
        this.$bvToast.toast("Dimensione non valida", {
          title: "Pizza non aggiunta",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      item.size_text = size;

      this.$store.dispatch("rewards/add_pizza", item);

      this.$bvToast.toast(
        `Hai inserito una "${item.pizza_name}" di dimensione "${size}"`,
        {
          title: `Hai aggiunto "${item.pizza_name}"!`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        }
      );

      window.setTimeout(() => {
        window.location.href = "/cart/rewards";
      }, 1000);
    },
    add_item() {
      const item = {
        qty: parseInt(this.form.quantity),
        dough: this.form.dough,
        size: this.form.size,
        pizza_id: this.details.id,
        pizza_name: this.details.name,
        prices: this.details.prices,
        slug: this.details.url,
        toppings: this.details.toppings,
      };

      let text_error = "";
      if (!(item.dough && item.size)) {
        text_error = `Devi selezionare prima l'"impasto", la "grandezza" e la "quantità"`;
      } else if (!Number(item.qty)) {
        text_error = "La quantità dev'essere un numero";
      }

      if (text_error != "") {
        this.$bvToast.toast(text_error, {
          title: "Pizza non aggiunta",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      this.$store.dispatch("cart/add_item", { type: "pizza", item });

      const h = this.$createElement;

      const vNodeMsg = h("div", [
        h("p", "Pizza inserita nel carrello"),
        h("a", { attrs: { href: "/cart" } }, "Clicca e vai al carrello adesso"),
      ]);

      this.$bvToast.toast([vNodeMsg], {
        title: `Hai aggiunto ${item.qty} "${item.pizza_name}"!`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
  },
  async mounted() {
    // If the user searchs a pizza by not valid id (ids are numbers)
    if (!this.username && !Number(this.pizza)) {
      window.location.href = "/pizza";
    }

    this.$store.dispatch("pizzas/find", { ...this.$props }).then((status) => {
      if (status != 200) {
        if (this.username) {
          window.location.href = "/u/" + this.username;
        } else {
          window.location.href = "/pizza";
        }
      }
      this.$set(this.form, "size", this.details.prices[0].size);
      this.$store.dispatch("orders/get_settings");
    });

    await this.$store.dispatch("pizzas/get_doughs");
    this.$store.dispatch("pizzas/get_sizes").then((result) => {
      for (const x of result) {
        this.size_options.push({
          text: x.name,
          value: x.id,
        });
      }
    });
  },
};
</script>
