<template lang="pug">
  b-form-group
    validation-provider(
      :rules="rules" v-slot="validationContext" :vid="name"
    )
      b-form-select(
        v-if="local_type == 'select'"
        @input="handleChange" :placeholder="label.toLowerCase()"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
        :options="options"
      )
      b-form-textarea(
        v-else-if="local_type == 'textarea'"
        @input="handleChange" :placeholder="label.toLowerCase()"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
        rows="3"
        max-rows="3"
      )
      b-input(
        v-else
        @input="handleChange" :placeholder="label.toLowerCase()" :type="local_type"
        :state="getValidationState(validationContext)"
        :aria-describedby="'input'+name+'feedback'" :value="model"
      )
      span.password(
        v-if="type == 'password'"
        :class="{'_p2': local_type == 'text'}"
        @click="passwordEye"
      )
      b-form-invalid-feedback(:id="'input'+name+'feedback'")
        | {{ invalid_text }}
</template>

<script>
export default {
  name: "FormInput",
  props: ["label", "rules", "model", "name", "invalid_text", "type", "options"],
  data() {
    return {
      local_type: this.$props.type,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    handleChange(event) {
      this.$emit("input", event);
    },
    passwordEye() {
      this.local_type = this.local_type == "password" ? "text" : "password";
    },
  },
};
</script>
