export default {
  namespaced: true,
  state: {
    ingredients: [],
    groups: [],
  },
  getters: {
    get_grouped_ingredients: (state) => {
      const groups = state.groups.map((x) => x.id);

      let data = {};
      for (const i of groups) {
        data[i] = state.ingredients.filter((x) => x.group == i);
      }

      return data;
    },
    get_groups: (state) => {
      return state.groups.sort((x) => x.position);
    },
    to_list: () => (ingredients) => {
      // From a list of ingredients, returns a string of them
      let toppings = [];
      let str;
      if (ingredients && ingredients["toppings"]) {
        for (const topping of ingredients.toppings) {
          let topping_str;
          if (topping["ingredient"]) topping_str = topping.ingredient.name;
          else topping_str = topping.name;

          topping_str = topping_str.toLowerCase();

          if (topping["abundant"]) topping_str += "+";

          if (topping["ingredient"]) {
            if (!topping["ingredient"]["is_available"]) {
              topping_str = `<span class="not-available">${topping_str}</span>`;
            }
          }
          toppings.push(topping_str);
        }
        str = toppings.join(", ");
        str = str.charAt().toUpperCase() + str.substr(1);
        str += ".";
      }
      return str;
    },
    to_reduced_list: (_, getters) => (pizza, num) => {
      // Print only the first `num` ingredients. If a pizza has more than `num`
      // appends `...` at the end
      if (pizza["toppings"].length <= num) {
        return getters.to_list(pizza);
      }

      let pizza_clone = JSON.parse(JSON.stringify(pizza));
      pizza_clone.toppings = pizza_clone.toppings.slice(0, num);

      let str = getters.to_list(pizza_clone); // This string ends with a '.'
      str += "..";

      return str;
    },
  },
  mutations: {
    save_ingredients: (state, data) => {
      state.ingredients = data["results"];
    },
    save_groups: (state, data) => {
      state.groups = data["results"];
    },
  },
  actions: {
    async grouped_ingredients({ commit, state, rootState }) {
      fetch(`${rootState.api}/pizzas/ingredients/groups/?limit=1000`).then(
        async (response) => {
          commit("save_groups", await response.json());
        }
      );

      await fetch(`${rootState.api}/pizzas/ingredients/?limit=1000`).then(
        async (response) => {
          commit("save_ingredients", await response.json());
        }
      );

      return state.get_grouped_ingredients;
    },
  },
};
