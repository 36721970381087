<template lang="pug">
  main#franchising
    header-red
    sidebar

    .banner-franchising

    section#slider-grid
      b-container.description
        .home-slider-grid
          h5._border Perchè far parte del mondo Conzatù
          slick#grid-slick(ref="slick" :options="slick_options")
            aside
              h3 Punto di forza
              img(src="@/assets/logotype.svg")
              p Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lob.
            aside
              h3 Business
              img(src="@/assets/euros.svg")
              p Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lob.
            aside
              h3 Community
              img(src="@/assets/community.svg")
              p Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lob.
    b-container.description
      h5 Entra nel mondo Conzatù
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form.delivery(@submit.stop.prevent="handleSubmit(onSubmit)")
          b-row
            b-col(md="6" cols="12")
              form-input(
                label="Nome e cognome" v-model="form.name" type="text"
                :model="form.name" name="name"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
              form-input(
                label="Telefono" v-model="form.phone" type="text"
                :model="form.phone" name="phone"
                :rules="{min: 7, required: true}"
                invalid_text="Il campo è obbligatorio"
              )
              form-input(
                label="Come hai conosciuto Conzatù?" v-model="form.note" type="text"
                :model="form.note" name="note"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
            b-col(md="6" cols="12")
              form-input(
                label="Indirizzo email" v-model="form.email" type="email"
                :model="form.email" name="email"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
              )
              form-input(
                label="" v-model="form.budget" type="select"
                :model="form.budget" name="budget"
                :rules="{required: true}"
                invalid_text="Il campo è obbligatorio"
                :options="budget_options"
              )
            b-col(md="6" cols="12")
              input(hidden :model="form.hcaptcha_passcode")
              vue-hcaptcha(:sitekey="hcaptcha_client" @verify="onVerify")
          b-form-group
            b-button(type="submit" variant="primary") Invia
          p.disclaimer Riceverai in <b>omaggio</b> una guida che ti aiuterà a capire perchè e come investire su Conzatù

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import Slick from "vue-slick";
import FormInput from "@/components/FormInput.vue";

import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "Franchising",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
    "form-input": FormInput,
    Slick,
    VueHcaptcha,
  },
  data() {
    return {
      form: {
        budget: null,
        hcaptcha_passcode: null,
      },
      budget_options: [
        { value: null, text: "budget da investire" },
        { value: "20000", text: "fino a 20 mila" },
        { value: "30000", text: "fino a 30 mila" },
        { value: "50000", text: "fino a 50 mila" },
      ],
      hcaptcha_client: process.env.VUE_APP_HCAPTCHA_CLIENT,
      slick_options: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false,
              infinite: true,
              slidesToShow: 1,
              autoplay: true,
              autoplaySpeed: 2000,
            },
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      if (!this.form.hcaptcha_passcode) {
        this.$bvToast.toast("Risolvi il captcha prima", {
          title: "Captcha",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      const url = `${this.$store.getters.api_endpoint}/franchise/`;
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.form),
      })
        .then((response) => {
          if (response.status == 200) {
            this.$bvToast.toast("Sarai presto ricontattato", {
              title: "Contatto inviato con successo",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
            this.form = { budget: null };
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e, {
            title: "Errore nel contatto",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        });
    },
    onVerify(token) {
      this.form.hcaptcha_passcode = token;
    },
  },
};
</script>
