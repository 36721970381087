<template lang="pug">
  main
    header-red
    sidebar

    b-container
      #pizza-details
        .header
          a.back(@click="goback" href="#")
            span torna indietro

    b-container#edit-usersettings
      h3 Info personali
        b-button.edit-btn(variant="primary" @click="editinfo('user')")
          | Modifica
      b-row
        b-col(cols="12" md="3")
          h4 Email
          // TODO: wait ch9440 for fix
          b-input(
            v-if="edits == 'user'" :value="user.email" type="email" readonly
          )
          div(v-else)
            p(v-if="user") {{ user.email }}
        b-col(cols="12" md="3")
          h4 Nome
          b-input(
            v-model="form.name" v-if="edits == 'user'"
            :placeholder="user.name"
          )
          div(v-else)
            p(v-if="user") {{ user.name }}
        b-col(cols="12" md="3")
          h4 Telefono
          b-input(
            v-model="form.phone" v-if="edits == 'user'"
            :placeholder="user.phone"
          )
          div(v-else)
            p(v-if="user") {{ user.phone }}
        b-col(cols="12" md="3")
          h4 Cellulare
          b-input(
            v-model="form.cellular" v-if="edits == 'user'"
            :placeholder="user.cellular"
          )
          div(v-else)
            p(v-if="user") {{ user.cellular }}

      b-button.save(variant="primary" v-if="edits == 'user'" @click="saveme()")
        | Salva
      hr
      b-button(href="/settings/password") Cambia la password

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "UserSettings",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  data() {
    return {
      edits: "",
      form: {},
    };
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/get_me"];
    },
  },
  methods: {
    goback() {
      return window.history.back();
    },
    editinfo(type) {
      if (type == this.edits) {
        this.edits = "";
      } else {
        this.edits = type;
      }
    },
    saveme() {
      this.form.email == this.user.email;

      if (!this.form.name) {
        this.form.name == this.user.name;
      }
      if (!this.form.phone) {
        this.form.phone == this.user.phone;
      }
      if (!this.form.cellular) {
        this.form.cellular == this.user.cellular;
      }
      this.$store.dispatch("auth/edit_user_info", this.form).then((res) => {
        if (res) {
          this.$bvToast.toast("Informazioni salvate", {
            title: "Successo!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.editinfo("user");
          this.form = {};
        } else {
          this.$bvToast.toast("Informazioni non salvate", {
            title: "Errore!",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        }
      });
    },
  },
};
</script>
