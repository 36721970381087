<template lang="pug">
  main
    header-red
    sidebar(page="DownloadApp")

    .banner-download
      b-container
        #download-the-app
          h1 Scarica l'app e conza la pizza!
          ._apps
            a(href="https://apps.apple.com/it/app/conzatu/id1600270492" target="_blank")
              img(src="@/assets/app-ios.png" alt="iOS")
            a(href="https://play.google.com/store/apps/details?id=com.liberotratto.conzatu" target="_blank")
              img(src="@/assets/app-android.png" alt="Android")

    b-container
      section#boxes-3.download-app.extended
        article
          .img(style="background-image:url(/download-app-img1.png)")
          .t
            h3 Ordina la pizza in pochi click
            p La pizzeria CONZATU diventa un'app!
        article
          .t
            h3 Share the pizza!
            p Conzatù la pizza e condividila con i tuoi amici
          .img.r(style="background-image:url(/download-app-img2.png)")
        article
          .img(style="background-image:url(/download-app-img3.png)")
          .t
            h3 Ami la pizza?
            p Diventa Brand Ambassador di 
              img(src="/logo-red.svg" alt="logo")
    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Home",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
};
</script>
