<template lang="pug">
  section#boxes-3(:class="{'extended': big != undefined}")
    article(v-for="block in blocks" :key="block.id")
      .t
        h2 {{ block.title }}
        p {{ block.description }}
        b-button(:href="block.button_link" variant="primary") {{ block.button_text }}
      .img(
        :style="{ 'background-image': 'url(' + block.photo + ')' }"
      )
        div
          b-button(:href="block.button_link" variant="primary") {{ block.button_text }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Boxes3",
  props: ["big"],
  computed: {
    ...mapGetters("promotions", ["blocks"]),
  },
  mounted() {
    this.$store.dispatch("promotions/get_blocks");
  },
};
</script>
