var slugify = require("slugify");

export default {
  namespaced: true,
  state: {
    pizzas: [],
    num: 0,
    per_page: 16,
    ingredients: {},
    details: {},
    sizes: [],
    doughs: [],
  },
  getters: {
    get_pizzas_list: (state) => {
      return state.pizzas;
    },
    get_pizzas_num: (state) => {
      return state.num;
    },
    get_per_page_num: (state) => {
      return state.per_page;
    },
    ingredients: (state) => {
      return state.ingredients;
    },
    details: (state) => {
      return state.details;
    },
    am_i_the_owner: (state, getters, rootState, rootGetters) => {
      if (!rootGetters["auth/is_logged"]) return false;

      if (rootState.auth.user) {
        return state.details.owner_username == rootState.auth.user.username;
      }

      return false;
    },
    images: () => (image) => {
      // TODO: returns a list of images
      let imgs = [];
      imgs.push(`${process.env.VUE_APP_MEDIA_URL}/pizzas/${image}`);

      return imgs;
    },
    dough_options: (state) => {
      const options = state.doughs.map((x) => {
        return {
          text: x.name,
          value: x.id,
          extra: parseFloat(x.price),
          disabled: !x.is_available,
        };
      });

      return options;
    },
    dough_name: (state) => (dough_id) => {
      const dough = state.doughs.filter((x) => x.id == dough_id)[0];
      if (dough) return dough.name;

      return "";
    },
    sizes: (state) => {
      return state.sizes;
    },
    size_price: (state) => (size_id, prices) => {
      if (!size_id) {
        return "";
      }

      if (!prices) {
        prices = state.details.prices;
      }

      for (const price of prices) {
        if (price.size == size_id) {
          return price.price;
        }
      }
    },
    pizza_price: (state, getters) => (size_id, prices, dough) => {
      let price = parseFloat(getters.size_price(size_id, prices));
      if (dough) {
        let el = getters.dough_options.filter((x) => x.value == dough);
        if (el.length > 0) {
          el = el[0];
          price += el.extra;
        }
      }

      return price;
    },
  },
  mutations: {
    save_pizzas: (state, items) => {
      state.pizzas = items;
    },
    save_pizzas_num: (state, count) => {
      state.num = count;
    },
    save_ingredients: (state, ingredients) => {
      state.ingredients = ingredients;
    },
    save_pizza_info: (state, details) => {
      state.details = details;
      state.details.new_description = details.description;
    },
    save_pizza_doughs: (state, doughs) => {
      state.doughs = doughs;
    },
    save_pizza_sizes: (state, sizes) => {
      state.sizes = sizes;
    },
  },
  actions: {
    async all({ commit, state, rootState, rootGetters }, data) {
      let url = `${rootState.api}/pizzas/all/`;
      const headers = {};
      let body = null;
      let method = "GET";

      if (data.auth) {
        url = `${rootState.api}/pizzas/`;
        headers["Authorization"] = `Bearer ${rootGetters["auth/access_token"]}`;
      } else if (data.user) {
        url = `${rootState.api}/pizzas/u/${data.user}/`;
      }
      if (data.query) {
        url = `${rootState.api}/pizzas/search/`;
        if (data.auth) {
          url += "mine/";
        }

        headers["Content-Type"] = "application/json";
        method = "POST";
        body = {};
        body["q"] = data.query;
        if (data.user) {
          body["owner"] = data.user;
        }
      }

      let per_page = state.per_page;
      if (data.per_page) per_page = data.per_page;

      let return_404 = false;

      url += `?limit=${per_page}&offset=${(data.page - 1) * per_page}`;

      if (data["filters"]) {
        for (const filter of Object.keys(data.filters)) {
          if (data.filters[filter]) {
            url += `&${filter}`;
          }
        }
      }

      await fetch(url, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
      }).then(async (response) => {
        const status = await response.status;
        if (status == 404) {
          return_404 = true;
        } else {
          const data = await response.json();
          commit("save_pizzas", data.results);
          commit("save_pizzas_num", data.count);
        }
      });

      if (return_404) return 404;
      return state.pizzas;
    },
    async user_pizza({ dispatch }, data) {
      return dispatch("all", {
        page: data.page,
        user: data.user,
        filters: data.filters,
      });
    },
    generate({ commit }, ingredients) {
      // The parameter `ingredients` is 'save_ingredients', called with
      // `activated ingredients` and `ingredients grouped by category`.
      // First of all this function creates two lists: one for all ingredients
      // (not grouped) and one for the ids of activated ingredients (`activated
      // ingredients` is {key: boolean} where `key` is the id and `boolean` a
      // bool value (true or false). We want only the trues.
      const ingredients_groups = [];
      for (const key of Object.keys(ingredients.groups)) {
        ingredients_groups.push(...ingredients.groups[key]);
      }

      const ingredients_activated = [];
      for (const key of Object.keys(ingredients.activated)) {
        if (ingredients.activated[key]) {
          ingredients_activated.push(parseInt(key));
        }
      }

      const ingredients_filtered = ingredients_groups.filter(
        (x) => ingredients_activated.indexOf(x.id) > -1
      );

      for (const key of Object.keys(ingredients.after_baking)) {
        const state = ingredients.after_baking[key];
        const ingrId = ingredients_filtered.filter((x) => x.id == key)[0];
        if (ingrId) {
          const ingrIndex = ingredients_filtered.indexOf(ingrId);
          if (ingrIndex > -1) {
            ingredients_filtered[ingrIndex]["is_after_baking"] = state;
          }
        }
      }

      commit("save_ingredients", ingredients_filtered);
    },
    async search({ dispatch }, data) {
      // This is different than `find` action, because it search a pizza as
      // defined in [ch10812]
      dispatch("all", {
        page: data.page,
        filters: data.filters,
        query: data.query,
        user: data.user,
        auth: data.auth,
      });
    },
    async find({ commit, rootState, rootGetters }, search) {
      // If `search` has both `username` and `pizza` search without
      // Authorization, else search with Authorization by id (`pizza` in this
      // case)
      let status;
      let url = `${rootState.api}/pizzas/`;
      const headers = {};

      if (search.username) {
        url += `u/${search.username}/${search.pizza}/`;
      } else {
        url += `${search.pizza}/`;
        headers["Authorization"] = `Bearer ${rootGetters["auth/access_token"]}`;
      }

      await fetch(url, { headers }).then(async (response) => {
        status = await response.status;
        if (status == 200) {
          commit("save_pizza_info", await response.json());
        }
      });

      return status;
    },
    async get_doughs({ commit, rootState }) {
      await fetch(`${rootState.api}/pizzas/doughs/`).then(async (response) => {
        const data = await response.json();
        commit("save_pizza_doughs", data.results);
      });
    },
    async get_sizes({ commit, rootState }) {
      let data;
      await fetch(`${rootState.api}/pizzas/sizes/`).then(async (response) => {
        data = await response.json();
        commit("save_pizza_sizes", data.results);
      });

      return data.results;
    },
    async create({ commit, rootGetters, rootState }, data) {
      let res = { status: 400, data: {} };
      if (!data.name) {
        res.data["detail"] = "La pizza deve avere un nome";
        return res;
      }

      if (slugify(data.name, { strict: true, remove: /_/g }) == "") {
        res.data["detail"] = "Questo nome non è valido";
        return res;
      }

      let ingredients_num = 0;
      const ingredients_list = [];
      for (const key of Object.keys(data.active_ingredients)) {
        if (data.active_ingredients[key]) {
          ingredients_num++;
          ingredients_list.push(key);
        }
      }

      if (ingredients_num < 2) {
        res.data["detail"] = "Non puoi aggiungere meno di 2 ingredienti";
        return res;
      }

      if (ingredients_num > 10) {
        res.data["detail"] = "Non puoi aggiungere più di 10 ingredienti";
        return res;
      }

      const payload = {
        name: data.name,
        toppings: [],
      };

      for (const id of ingredients_list) {
        payload.toppings.push({
          ingredient: id,
          after_baking: Object.prototype.hasOwnProperty.call(
            data.after_baking_ingredients,
            id
          )
            ? data.after_baking_ingredients[id]
            : false,
          abundant: Object.prototype.hasOwnProperty.call(
            data.abundant_ingredients,
            id
          )
            ? data.abundant_ingredients[id]
            : false,
        });
      }

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/pizzas/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });

      return res;
    },
    async edit({ commit, rootGetters, rootState }, data) {
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/pizzas/${data.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(data.payload),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async delete_pizza({ commit, dispatch, rootGetters, rootState }, data) {
      // Remove an address
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/pizzas/${data.pizza_id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      })
        .then(async (response) => {
          res.status = await response.status;

          if (res.status == 204) {
            dispatch("all", { auth: true, page: data.page_num });
          } else {
            res.data = await response.json();
          }
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });

      return res;
    },
  },
};
