<template lang="pug">
  div
    b-container
      section.header-filters
        h4 Ordina
        b-form(inline)
          b-form-radio(
            @change="filter('order_by_feedback')"
            value="order_by_feedback"
            v-model="filter_type"
          )
            | recensioni
          b-form-radio(
            @change="filter('order_by_time_ordered')"
            value="order_by_time_ordered"
            v-model="filter_type"
          )
            | più vendute
          b-form-radio(
            @change="filter('order_by_created')"
            value="order_by_created"
            v-model="filter_type"
          )
            | pizze nuove
        #search-box
          b-form
            b-form-group(id="input-text-search")
              b-input(
                id="input-text-search"
                placeholder="ricerca pizza"
                type="text"
                name="q"
                v-model="q"
              )
            b-button#search-btn(variant="light" type="submit")

    b-container(v-if="!auth")
      div(v-if="user")
        br
        h4 Pizze di 
          b {{ user }}
      b-row#pizzalist
        b-col.pizza(md="3" v-for="pizza in pizzas" :key="pizza.id")
          a(:href="'/u/'+pizza.url")
            .details-top
              .l
                strong ordinata
                span {{ pizza.times_ordered || 0 }} volte
              .r
                strong voto
                span.stars {{ trunc_feedback(pizza.feedback) }}
            img.pizza-img(:src="media_base+'/pizzas/'+pizza.image")
            .details-bottom
              h2 {{ pizza.name }}
              span.author(v-if="pizza.is_staff_choice") scelta dallo staff
              span.author(v-else) {{ pizza.owner }}
              p.ingredients(v-html="to_reduced_list(pizza, 5)")
              span.price €{{ pizza.prices[0].price }}
    b-container(v-else)
      b-row#pizzalist._na
        b-col.pizza(md="3" cols="6" v-for="pizza in pizzas" :key="pizza.id")
          a(:href="'/u/'+pizza.url")
            img.pizza-img(:src="media_base+'/pizzas/'+pizza.image")
            .details-bottom
              h2 {{ pizza.name }}
          .hidden
            span.stars {{ trunc_feedback(pizza.feedback) }}/5
            .share-pizza
              .social-btns
                b-button#sb-email(:href="'mailto:?subject=&body=Ciao, guarda la pizza che ho creato! Clicca qui '+encodeURIComponent(get_url(pizza))")
                  .icon
                b-button#sb-wa(:href="'https://api.whatsapp.com/send/?phone=&text=Ciao,+guarda+la+pizza+che+ho+creato!+Clicca+qui+'+encodeURIComponent(get_url(pizza))+'&app_absent=0'")
                  .icon
                b-button#sb-tg(:href="'https://t.me/share/url?url='+encodeURIComponent(get_url(pizza))+'&text=Ciao, guarda la pizza che ho creato!'")
                  .icon

            b-button.delete-pizza(
              variant="outline-secondary"
              @click="delete_pizza(pizza.id)"
            ) Elimina

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="rows" per-page="16"
        first-number last-number @change="change_pag($event)"
      )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PizzaGrid",
  // `auth` is for /account/pizza
  props: ["all", "user", "auth"],
  data() {
    return {
      current_page: 1,
      page_num: 1,
      media_base: process.env.VUE_APP_MEDIA_URL,
      q: "",
      filter_type: "order_by_created",
    };
  },
  computed: {
    ...mapGetters("pizzas", {
      pizzas: "get_pizzas_list",
      rows: "get_pizzas_num",
    }),
    ...mapGetters("orders", ["trunc_feedback"]),
    ...mapGetters("ingredients", ["to_reduced_list"]),
  },
  mounted() {
    this.page_num = parseInt(this.$route.query["p"]) || 1;
    this.q = this.$route.query["q"];

    if (this.$route.query["order_by_feedback"])
      this.filter_type = "order_by_feedback";
    if (this.$route.query["order_by_time_ordered"])
      this.filter_type = "order_by_time_ordered";
    if (this.$route.query["order_by_created"])
      this.filter_type = "order_by_created";

    const filters = {};
    filters[this.filter_type] = 1;
    if (this.q) {
      this.$store.dispatch("pizzas/search", {
        query: this.q,
        page: this.page_num,
        user: this.$props.user,
        auth: this.$props.auth ?? false,
      });
    } else if (this.$props.all) {
      this.$store.dispatch("pizzas/all", {
        auth: this.$props.auth ?? false,
        page: this.page_num,
        filters,
      });
    } else {
      this.$store
        .dispatch("pizzas/user_pizza", {
          user: this.$props.user,
          page: this.page_num,
          filters,
        })
        .then((response) => {
          if (response == 404) {
            this.$router.replace({ name: "Page404" });
          }
        });
    }
    setTimeout(() => {
      this.$set(this.$data, "current_page", this.page_num);
    }, 800);
  },
  methods: {
    change_pag(pagenum) {
      this.current_page = pagenum;
      this.filter();
    },
    filter(filter_type) {
      let url = this.$route.path;
      if (this.current_page > 1) {
        url += `?p=${this.current_page}&`;
      } else {
        url += `?`;
      }

      if (filter_type) {
        url = `?${filter_type}=1`;
      } else {
        filter_type = this.filter_type;
        url += `${filter_type}=1&`;
      }

      window.location.href = url;
    },
    get_url(pizza) {
      let url = window.location.origin;
      url += `/u/${pizza.url}`;

      return url;
    },
    delete_pizza(pizza_id) {
      this.$store
        .dispatch("pizzas/delete_pizza", { pizza_id, page_num: this.page_num })
        .then((res) => {
          if (res.status != 204) {
            if (res.data["detail"]) {
              this.$bvToast.toast(res.data.detail, {
                title: "Errore durante la rimozione della pizza!",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              });
            }
          } else {
            this.$bvToast.toast("Adesso non la troverai più nella tua lista", {
              title: "Pizza eliminata!",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        });
    },
  },
};
</script>
