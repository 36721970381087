<template lang="pug">
  main#account
    header-red
    sidebar(:mobileonly="true")

    new-address(
      :form="forms.address"
      ref="new-address"
      :hidden="() => this.$store.dispatch('blur_status')"
    )

    b-container#main(:class="{'blur': have_to_blur}")
      b-row
        b-col#details(md="4" cols="12")
          img(src="@/assets/circle-account-red.svg")
          aside(v-if="get_me")
            h4 {{ get_me.name || get_me.username }}
            p {{ get_me.email }}
            p {{ get_me.phone }}
            p {{ get_me.cellular }}

        b-col.points-num(md="4" cols="0")
          .flex-points-header
            h4 Punti conzate
            b-button(
              variant="outline-secondary"
              href="/points"
            ) Dettagli
          h2(v-if="points.total") {{ points.total.toFixed(2) }}
        b-col(md="4" cols="12")
          div.r
            b-button.orders(href="/orders") I miei ordini
            a.settings(href="/settings") Impostazioni

      b-row
        b-col(md="5" cols="12")
          h3 Indirizzi salvati
          ul#addresses(v-if="addresses && addresses.results")
            li(
              v-for="address in addresses.results.slice(address_start, address_start+3)"
              :key="address.id"
            )
              b-card
                .actions
                  b-row.mb-0
                    b-col
                      img(
                        src="@/assets/edit-black.svg"
                        @click="handle_address_modal(address)"
                      )
                    b-col
                      img(
                        src="@/assets/trash-red.png"
                        @click="remove_address(address.id)"
                      )
                  b-row.mb-0
                    b-col
                    b-col
                      img(
                        src="@/assets/star-red.svg"
                        title="Indirizzo di default"
                        v-if="address.is_default"
                      )
                      img(
                        src="@/assets/star-black.svg"
                        @click="make_default(address.id)"
                        title="Clicca per rendere questo indirizzo quello di default"
                        v-else
                      )

                h4 {{ address.name }}
                span {{ serialize_address(address) }} <br>
                span {{ address.cellular }}
                b-badge(pill variant="success" v-if="address.is_delivery_available")
                  | disponibile al domicilio

          #pagination
            b-pagination(
              v-model="current_page" :total-rows="addresses.count" per-page="3"
              first-number last-number @change="change_pag($event)"
            )

          b-button.add(@click="handle_address_modal()") Aggiungi indirizzo

        b-col(md="1" cols="0")

        b-col(md="6" cols="12")
          h3 Pizze create
            a(href="/account/pizza") vedi tutte

          b-row#pizzalist
            b-col.pizza(md="3" cols="6" v-for="pizza in pizzas" :key="pizza.id")
              a(:href="'/u/'+pizza.url")
                img.pizza-img(:src="media_base+'/pizzas/'+pizza.image")
                .details-bottom
                  h2 {{ pizza.name }}
                  span.ingredients(v-html="to_list(pizza)")
 
    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import NewAddress from "@/components/NewAddress.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Account",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "new-address": NewAddress,
  },
  data() {
    return {
      forms: {
        address: {},
      },
      current_page: 1,
      address_start: 0,
      media_base: process.env.VUE_APP_MEDIA_URL,
    };
  },
  computed: {
    ...mapGetters(["have_to_blur"]),
    ...mapGetters("auth", ["get_me"]),
    ...mapGetters("auth/addresses", ["addresses", "serialize_address"]),
    ...mapGetters("pizzas", { pizzas: "get_pizzas_list" }),
    ...mapGetters("ingredients", ["to_list"]),
    ...mapGetters("rewards", ["points"]),
  },
  mounted() {
    this.$store.dispatch("rewards/get_my_points");
    this.get_addresses();
    this.$store.dispatch("pizzas/all", { auth: true, page: 1, per_page: 4 });
  },
  methods: {
    change_pag(pagenum) {
      const offset_start = pagenum - 1;

      if (
        this.addresses.next &&
        this.addresses.results.length != this.addresses.count &&
        offset_start * 3 + 3 > this.addresses.results.length
      ) {
        this.get_addresses(this.addresses.next);
      }

      setTimeout(
        () => {
          const offset_start = pagenum - 1;
          if (
            this.addresses.next &&
            this.addresses.results.length != this.addresses.count &&
            offset_start * 3 + 3 > this.addresses.results.length
          ) {
            this.change_pag(pagenum);
          }
        },
        500,
        pagenum
      );
      this.address_start = offset_start * 3;
    },
    handle_address_modal(address) {
      this.$store.dispatch("blur_status");
      this.$refs["new-address"].$refs["modal"].show();

      if (address) {
        this.forms.address = { ...address };
      } else {
        this.forms.address = {};
      }
    },
    remove_address(address_id) {
      this.$store
        .dispatch("auth/addresses/remove_address", address_id)
        .then((res) => {
          if (res.status != 204) {
            if (res.data["detail"]) {
              this.$bvToast.toast(res.data.detail, {
                title: "Errore durante la rimozione dell'indirizzo!",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              });
            }
          } else {
            this.$bvToast.toast("Adesso non lo troverai più nella tua lista", {
              title: "Indirizzo eliminato!",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        });
    },
    ...mapActions("auth/addresses", ["get_addresses", "make_default"]),
  },
};
</script>
