<template lang="pug">
  main
    header-red
    sidebar(page="CreatePizza")
    main#conzapizza
      b-container.top#pizza-details
        main
          aside#info
            #fix-box(:class="pizza_box_class")
              pizza
              div
                b-form-group#choose-name
                  h4 scegli il nome della tua pizza
                  b-input(v-model="form.name" id="name")

              .buttons
                div
                  b-button(variant="primary" @click="create_pizza") Crea la pizza
                  b-button(variant="outline-dark" href="/pizza") Pizze più vendute

          aside(v-scroll="fix_pizza_box_scrolling")
            #ingredients
              .header
                h4 Ingredienti
                  span max 10
                search-ingredient(
                  :groups="ingredients"
                  @update="toggle_ingredient($event)"
                )

              b-container#ingredients
                .group(v-for="group in groups")
                  h6 {{ group.name }}:
                  .list-of-ingredients
                    b-row
                      b-col(
                        cols="6" md="auto"
                        v-for="ingredient in ingredients[group.id]"
                        :key="ingredient.id"
                        v-if="ingredient.is_available"
                      )
                        b-form-checkbox.box(
                          :id="'ingredient'+ingredient.id"
                          v-model="form.active_ingredients[ingredient.id]"
                          :class="{active: form.active_ingredients[ingredient.id]}"
                          @change="generate_pizza"
                        )
                          icon(:i="ingredient.icon")
                          | {{ ingredient.name }}
                        .extras(v-if="form.active_ingredients[ingredient.id]")
                          b-form-checkbox(
                            v-model="form.abundant_ingredients[ingredient.id]"
                            :class="{active: form.abundant_ingredients[ingredient.id]}"
                            v-if="ingredient.can_be_abundant"
                          ) abbondante
                          b-form-checkbox(
                            v-model="form.after_baking_ingredients[ingredient.id]"
                            :class="{active: form.after_baking_ingredients[ingredient.id]}"
                            v-if="ingredient.can_be_put_after_baking"
                            @change="generate_pizza"
                          ) fuori cottura
                        span.lactose-free(v-if="ingredient.is_lactose_free")
                          | Senza lattosio
                        span.gluten-free(v-if="ingredient.is_gluten_free")
                          | Senza glutine


      b-container
        hr
        boxes-3

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import PizzaImage from "@/components/PizzaImage.vue";
import SearchIngredient from "@/components/SearchIngredient.vue";
import Icon from "@/components/Icon.vue";
import Boxes3 from "@/components/Boxes3.vue";

const flatten = require("lodash/flatten");

export default {
  name: "CreatePizza",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    pizza: PizzaImage,
    "search-ingredient": SearchIngredient,
    "boxes-3": Boxes3,
    icon: Icon,
  },
  data() {
    return {
      form: {
        active_ingredients: {},
        abundant_ingredients: {},
        after_baking_ingredients: {},
      },
      ingredients_num: 0,
      pizza_box_class: "absolute",
    };
  },
  computed: {
    ingredients: function () {
      return this.$store.getters["ingredients/get_grouped_ingredients"];
    },
    groups: function () {
      return this.$store.getters["ingredients/get_groups"];
    },
  },
  async mounted() {
    await this.$store.dispatch("ingredients/grouped_ingredients");
    if (this.$route.query["ingredients"]) {
      const query_keys = [
        { q: "id[]", key: "active_ingredients" },
        { q: "ab[]", key: "after_baking_ingredients" },
        { q: "a[]", key: "abundant_ingredients" },
      ];

      let available_ingredients = [];
      for (const key of Object.keys(this.ingredients)) {
        this.ingredients[key].forEach((x) => {
          if (x.is_available) available_ingredients.push(x.id);
        });
      }

      available_ingredients = flatten(available_ingredients);
      for (const query of query_keys) {
        const q = this.$route.query[query.q];
        if (q) {
          if (typeof q == "string") {
            if (available_ingredients.indexOf(parseInt(q)) >= 0)
              this.$set(this.form[query.key], q, true);
          } else {
            for (const x of q) {
              if (available_ingredients.indexOf(parseInt(x)) >= 0)
                this.$set(this.form[query.key], x, true);
            }
          }
        }
      }

      window.setTimeout(() => {
        this.generate_pizza();
      }, 1000);
    }
  },
  methods: {
    fix_pizza_box_scrolling() {
      const scrollY = window.scrollY;
      const box_height = document.getElementById("fix-box").offsetHeight + 180;
      const scroll_stop =
        document.getElementById("boxes-3").offsetTop - box_height;

      console.log(scrollY, scroll_stop);

      if (scrollY >= 330 && scrollY <= scroll_stop) {
        this.pizza_box_class = "fixed";
      } else {
        this.pizza_box_class = "absolute";
      }
    },
    toggle_ingredient(id) {
      this.$set(
        this.form.active_ingredients,
        id,
        !this.form.active_ingredients[id]
      );
      this.generate_pizza();
    },
    create_pizza() {
      this.$store.dispatch("pizzas/create", { ...this.form }).then((res) => {
        console.log(res);
        if (res.status != 201) {
          let message = "Prova in caso con un altro nome";
          if (res.data["detail"]) {
            message = res.data.detail;
          }

          this.$bvToast.toast(message, {
            title: "Errore durante la creazione!",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        } else {
          this.$bvToast.toast("Sarai presto reindirizzato", {
            title: "La tua pizza è stata creata!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          setTimeout(() => {
            window.location.href = `/pizza/${res.data.id}`;
          }, 2000);
        }
      });
    },
    generate_pizza() {
      this.$store.dispatch("pizzas/generate", {
        activated: this.form.active_ingredients,
        after_baking: this.form.after_baking_ingredients,
        groups: this.ingredients,
      });
    },
  },
};
</script>
