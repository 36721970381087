<template lang="pug">
  main
    header-red
    sidebar

    pizzas-grid(:user="username")
    b-container
      hr
      boxes-3

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import Boxes3 from "@/components/Boxes3.vue";
import PizzasGrid from "@/components/PizzasGrid.vue";

export default {
  name: "PizzaList",
  props: ["username"],
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "boxes-3": Boxes3,
    "pizzas-grid": PizzasGrid,
  },
};
</script>
