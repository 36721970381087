export default {
  namespaced: true,
  state: {
    live_orders: [],
    orders: [],
    num: 0,
    per_page: 30,
    settings: {},
    live_stream: "",
  },
  getters: {
    get_live_orders: (state) => {
      const pizzas = [];
      state.live_orders.forEach((e) => {
        Array.prototype.push.apply(pizzas, e.pizzas);
      });

      return pizzas;
    },
    get_my_orders: (state) => {
      return state.orders;
    },
    get_my_orders_num: (state) => {
      return state.num;
    },
    get_per_page_num: (state) => {
      return state.per_page;
    },
    trunc_feedback: () => (feedback) => {
      if (!feedback) {
        return 0;
      }

      return feedback.toFixed(1);
    },
    orders_settings: (state) => {
      return state.settings;
    },
    are_orders_enabled: (state) => {
      if (!state.settings["are_orders_enabled"]) return false;

      return state.settings.are_orders_enabled;
    },
    live_stream: (state) => {
      let stream = "";
      if (state.live_stream.streams) {
        const id = state.live_stream.streams.split("v=")[1];
        stream = "https://www.youtube-nocookie.com/embed/" + id;
      }

      return stream;
    },
  },
  mutations: {
    save_live_orders: (state, pizzas) => {
      state.live_orders = pizzas;
    },
    save_my_orders: (state, data) => {
      state.orders = data;
    },
    save_orders_num: (state, count) => {
      state.num = count;
    },
    save_new_order: (state, order) => {
      state.orders.unshift(order);
    },
    save_settings: (state, settings) => {
      state.settings = settings;
    },
    save_live_stream: (state, stream) => {
      state.live_stream = stream;
    },
  },
  actions: {
    async all({ commit, state, rootState, rootGetters }, data) {
      // `data` is an object with `page: int` and `per_page: int`. Last of these
      // is used as overload of `state.per_page`.

      let per_page = state.per_page;
      if (data.per_page) per_page = data.per_page;

      fetch(
        `${rootState.api}/orders/mine/?limit=${per_page}&offset=${
          (data.page - 1) * per_page
        }`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
          },
        }
      ).then(async (response) => {
        const data = await response.json();
        commit("save_my_orders", data.results);
        commit("save_orders_num", data.count);
      });
    },
    // eslint-disable-next-line
    async find({ commit, state, rootState, rootGetters }, id) {
      // Find an order by `id`

      let data = null;

      await fetch(`${rootState.api}/orders/${id}/`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
      })
        .then(async (response) => {
          if ((await response.status) == 200) {
            data = await response.json();
          }
        })
        .catch((e) => {
          data = { error: e };
        });

      return data;
    },
    async pay_order({ commit, rootGetters, rootState }, order_id) {
      let data = null;
      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/orders/paypal-create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({ order_id }),
      }).then(async (response) => {
        data = await response.json();
      });

      commit("loading_status", false, { root: true });
      return data;
    },
    async paypal_capture({ commit, rootGetters, rootState }, paypal_id) {
      let data = null;
      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/orders/paypal-capture/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify({ paypal_id }),
      }).then(async (response) => {
        data = await response.json();
      });

      commit("loading_status", false, { root: true });
      return data;
    },
    async create({ commit, rootGetters, rootState }, form) {
      // Create an order
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });
      await fetch(`${rootState.api}/orders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(form),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
          if (res.status == 201) {
            commit("save_new_order", res.data);
          }
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async lives({ commit, rootState }) {
      // Get orders in live
      fetch(`${rootState.api}/orders/live/?limit=30`).then(async (response) => {
        const data = await response.json();
        commit("save_live_orders", data["results"]);
      });
    },
    async create_feedback({ commit, rootGetters, rootState }, payload) {
      let res = { status: 400, data: {} };

      commit("loading_status", true, { root: true });

      await fetch(`${rootState.api}/feedbacks/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`,
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {
          res.status = await response.status;
          res.data = await response.json();
        })
        .catch((e) => {
          res.data["detail"] = e;
        });

      commit("loading_status", false, { root: true });
      return res;
    },
    async get_settings({ commit, rootState }) {
      await fetch(`${rootState.api}/orders/settings/`).then(
        async (response) => {
          commit("save_settings", await response.json());
        }
      );
    },
    async get_live_page({ commit, rootState }) {
      await fetch(`${rootState.api}/live/youtube/`).then(async (response) => {
        commit("save_live_stream", await response.json());
      });
    },
  },
};
