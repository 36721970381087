<template lang="pug">
  b-row#pizza-cart
    b-col(md="3" cols="12")
      img.pizza(:src="get_img(pizza.slug)" :alt="pizza.slug")
      .details
        h3(:title="pizza.pizza_name") {{ pizza.pizza_name }}
        h3 €{{ float_precision(pizza_price(pizza.size, pizza.prices, pizza.dough)*pizza.qty) }}

    b-col.radios.horizontal(md="3" cols="6")
      b-form-group(label="Impasto:")
        b-form-radio-group(
          id="btn-radios-dough"
          v-model="pizza.dough"
          :options="dough_options"
          name="dough"
          button-variant="outline-primary"
          buttons
          @input="update_cart()"
        )

    b-col.radios.horizontal(md="3" cols="6")
      b-form-group(label="Grandezza:")
        b-form-radio-group(
          id="btn-radios-size"
          v-model="pizza.size"
          :options="size_options"
          name="size"
          button-variant="outline-primary"
          buttons
          @input="update_cart()"
        )

    b-col(md="2" cols="6")
      b-form-group.horizontal.qta(label="Quantità:")
        b-button(variant="secondary" @click="chg_qta(-1)") -
        b-form-input(
          type="number" min="1" max="99" name="quantity"
          v-model="pizza.qty" @input="check_qta()"
        )
        b-button(variant="secondary" @click="chg_qta(1)") +

    b-col.grid(md="1" cols="12")
      img.plus(
        src="@/assets/plus-square.png"
        alt="Duplica pizza"
        title="Duplica pizza"
        @click="duplicate_pizza(pizza)"
      )
      img.trash(
        src="@/assets/trash-red.png"
        alt="Rimuovi pizza"
        title="Rimuovi pizza"
        @click="remove_item(pizza.hash)"
      )

</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PizzaCart",
  props: ["pizza", "size_options", "dough_options"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("pizzas", ["pizza_price"]),
  },
  methods: {
    get_img(slug) {
      slug = slug.replace("/", "-");
      const image = `${process.env.VUE_APP_MEDIA_URL}/pizzas/${slug}.png`;

      return image;
    },
    check_qta() {
      this.pizza.qty = parseInt(this.pizza.qty);

      if (!this.pizza.qty || this.pizza.qty < 1) {
        this.pizza.qty = 1;
        this.$set(this.pizza, "qty", 1);
      }

      this.update_cart();
    },
    chg_qta(value) {
      let new_value = parseInt(this.pizza.qty) + value;
      if (!new_value) {
        new_value = 1;
      }

      this.$set(this.$props.pizza, "qty", new_value);
      this.update_cart();
    },
    remove_item(hash) {
      this.$store.dispatch("cart/remove_item", {
        type: "pizza",
        item: hash,
      });
    },
    float_precision(price) {
      if (!price) return 0;
      return price.toFixed(2);
    },
    ...mapActions("cart", ["update_cart", "duplicate_pizza"]),
  },
};
</script>
