<template lang="pug">
  div
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartPay",
  computed: {
    ...mapGetters("cart", [
      "items",
      "delivery",
      "address",
      "note",
      "delivery_time",
    ]),
    ...mapGetters("rewards", {
      reward_extfoods: "my_extfoods",
      reward_id: "my_reward_id",
      reward_pizzas: "my_reward_pizzas",
    }),
  },
  methods: {
    new_order(is_payment_on_delivery) {
      const form = {
        delivery: this.delivery,
        delivery_time: this.delivery_time,
        note: this.note,
        is_payment_on_delivery: is_payment_on_delivery,
        pizzas: [],
        extras: [],
      };

      if (this.address["street"]) {
        form["address"] = this.address;
      }

      for (const pizza of this.items["pizza"]) {
        form.pizzas.push({
          pizza: pizza.slug,
          quantity: pizza.qty,
          size: pizza.size,
          dough: pizza.dough,
        });
      }

      if (this.items["extfood"]) {
        for (const extfood of this.items["extfood"]) {
          form.extras.push({
            item: extfood.id,
            quantity: extfood.qty,
          });
        }
      }

      if (this.reward_id) {
        form.reward = this.reward_id;
        if (this.reward_extfoods) {
          form.extras_reward = [];
          for (const item of Object.values(this.reward_extfoods)) {
            form.extras_reward.push({ item });
          }
        }

        if (this.reward_pizzas) {
          if (Object.keys(this.reward_pizzas).indexOf(this.reward_id) >= 0) {
            form.pizzas_reward = [];
            for (const item of this.reward_pizzas[this.reward_id]) {
              form.pizzas_reward.push({
                pizza: item.pizza,
                size: item.size,
                dough: 1, // TODO: select the right dough
              });
            }
          }
        }
      }

      this.$store
        .dispatch("orders/create", form, { root: true })
        .then((res) => {
          if (res.status != 201) {
            if (!res.data["detail"]) {
              for (const key of Object.keys(res.data)) {
                for (const message of res.data[key]) {
                  this.$bvToast.toast(message, {
                    title: key,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                  });
                }
              }
            } else {
              this.$bvToast.toast(res.data.detail, {
                title: "Errore durante la creazione dell'ordine!",
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              });
            }
          } else {
            this.$bvToast.toast("Sarai presto reindirizzato", {
              title: "Ordine creato!",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });

            this.cart_clear();
            this.rewards_clear();
            if (is_payment_on_delivery) {
              setTimeout(() => {
                window.location.href = "/orders/" + res.data.id;
              }, 2000);
            } else {
              this.pay_order(res.data.id)
                .then((response) => {
                  setTimeout(() => {
                    window.location.href = response.links[1].href;
                  }, 2000);
                })
                .catch(() => {
                  setTimeout(() => {
                    window.location.href = "/orders/" + res.data.id;
                  }, 2000);
                });
            }
          }
        });
    },
    ...mapActions("auth/addresses", ["new_address"]),
    ...mapActions("cart", ["cart_clear"]),
    ...mapActions("rewards", ["rewards_clear"]),
    ...mapActions("orders", ["pay_order"]),
  },
};
</script>
