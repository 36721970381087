<template lang="pug">
  div
    b-container
      b-table.orders(:fields="fields" :items="orders")
        template(#cell(id)="data")
          span(:title="data.item.id")
            a(
              :href="'/orders/'+data.item.id"
            ) {{ data.item.id }}

        template(#cell(created)="data")
          | {{ data.item.created | moment('DD/MM/YYYY HH:mm') }}

        template(#cell(address)="data")
          span(v-if="data.item.address")
            | {{ serialize_address(data.item.address) }}

        template(#cell(products)="data")
          .products
            b {{ count_products(data.item) }}
            img(
              src="@/assets/down-arrow.svg"
              @click="show_products(data.item.id)"
              :class="{'_rot': products_showed == data.item.id}"
            )

            div(v-if="products_showed == data.item.id")
              span(v-for="pizza in data.item.pizzas" :key="pizza.id")
                | {{ pizza.quantity }} {{ pizza.name }}
              span(v-for="extfood in data.item.extras" :key="extfood.id")
                | {{ extfood.quantity }} {{ extfood.name }}

        template(#cell(feedback)="data")
          .vote(:title="data.item.feedback")
            .stars
              .star(
                v-for="i in 5"
                :class="{active: data.item.feedback >= i}"
                style="cursor:default"
              )

        template(#cell(price)="data") € {{ data.item.price }}

        template(#cell(actions)="data")
          b-button(@click="repeat_order(data.item)" variant="primary") Ripeti

      .orders.mobile
        b-row(v-for="order in orders" :key="order.id")
          b-col(cols="12")
            h5 Ordine
            span {{ order.id }}
            span {{ order.created | moment('DD/MM/YYYY HH:mm') }}
            span(v-if="order.address")
              | {{ serialize_address(order.address) }}

          b-col(cols="12")
            h5 Prodotti
            span(v-for="pizza in order.pizzas" :key="pizza.id")
              | {{ pizza.quantity }} {{ pizza.name }}
            span(v-for="extfood in order.extras" :key="extfood.id")
              | {{ extfood.quantity }} {{ extfood.name }}

          b-col(cols="12")
            h5 Recensione
            .vote(:title="order.feedback")
              .stars
                .star(
                  v-for="i in 5"
                  :class="{active: order.feedback >= i}"
                  style="cursor:default"
                )

          b-col(cols="12")
            h5 Totale
            span.price € {{ order.price }}
            b-button(@click="repeat_order(order)" variant="primary") Ripeti

    #pagination
      b-pagination(
        v-model="current_page" :total-rows="rows" per-page="30"
        first-number last-number @change="change_pag($event)"
      )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrdersList",
  data() {
    return {
      current_page: 1,
      page_num: 1,
      fields: [
        { key: "id", label: "Ordine" },
        { key: "created", label: "Data" },
        { key: "address", label: "Indirizzo" },
        { key: "products", label: "Prodotti" }, // this `key` is invalid
        { key: "feedback", label: "Recensioni" },
        { key: "price", label: "Totale" },
        { key: "actions", label: "Azioni" }, // this `key` is invalid
      ],
      products_showed: null,
    };
  },
  computed: {
    ...mapGetters("orders", {
      orders: "get_my_orders",
      rows: "get_my_orders_num",
    }),
  },
  mounted() {
    this.page_num = parseInt(this.$route.query["p"]) || 1;

    this.$store.dispatch("orders/all", { page: this.page_num }).then(() => {
      if (this.$route.query["token"]) {
        for (const order of this.orders) {
          if (!order.is_payment_on_delivery && !order.payment) {
            window.location.href = "/orders/" + order.id;
          }
        }
      }
    });
    setTimeout(() => {
      this.$set(this.$data, "current_page", this.page_num);
    }, 800);
  },
  methods: {
    change_pag(pagenum) {
      window.location.href = `/orders?p=${pagenum}`;
    },
    serialize_address(address) {
      let str = address.street + ", ";
      str += address.city + " ";
      str += `(${address.province})`;

      return str;
    },
    count_products(data) {
      const pizzas = data.pizzas
        .map((item) => item.quantity)
        .reduce((a, b) => a + b);

      let extras = 0;
      if (data.extras.length > 0) {
        extras = data.extras
          .map((item) => item.quantity)
          .reduce((a, b) => a + b);
      }

      return pizzas + extras;
    },
    show_products(data_id) {
      if (this.products_showed == data_id) {
        this.products_showed = null;
      } else {
        this.products_showed = data_id;
      }
    },
    repeat_order(order) {
      const payload = {
        pizzas: order.pizzas,
        extras: order.extras,
        delivery: order.delivery,
        delivery_time: order.delivery_time,
      };

      if (order.address) {
        payload.address = order.address;
      }

      this.$store.dispatch("orders/create", payload).then((res) => {
        if (res.status != 201) {
          if (!res.data["detail"]) {
            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            this.$bvToast.toast(res.data.detail, {
              title: "Errore durante la creazione dell'ordine!",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        } else {
          this.$bvToast.toast("Sarà presto elaborato", {
            title: "Ordine creato!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        }
      });
    },
  },
};
</script>
