<template lang="pug">
  #search-ingredient
    #search-box
      b-input(placeholder="ricerca ingredienti" v-model="search_text")
      b-button#search-btn(variant="light" type="submit")
    .ingredients-box.form-control(v-show="search_text != ''")
      div(v-for="ingredients in groups")
        .ingred(
          v-for="ingredient in ingredients"
          @click="$emit('update', ingredient.id);search_text=''"
          v-if="good_to_show(ingredient)"
          :key="ingredient.id"
        )
          icon(:i="ingredient.icon")
          | {{ ingredient.name }}

</template>

<script>
import Icon from "@/components/Icon.vue";

export default {
  name: "SearchIngredient",
  props: ["groups"],
  components: {
    icon: Icon,
  },
  data() {
    return {
      search_text: "",
    };
  },
  methods: {
    good_to_show(ingredient) {
      return (
        ingredient.is_available &&
        ingredient.name.toLowerCase().indexOf(this.search_text.toLowerCase()) >
          -1
      );
    },
  },
};
</script>
