<template lang="pug">
  section#share-box-modal
    h3 Condividi la tua pizza con i tuoi amici e guadagna conzate!
    b-form
      b-form-group(id="input-copy-url")
        input.form-control(
          id="input-copy-url" type="text" v-model="message" readonly
        )
      b-button#copy-btn(
        variant="dark" type="button" v-clipboard:copy="message"
        v-clipboard:success="on_copy" v-clipboard:error="on_error"
      )

    .social-btns
      b-button#sb-email(:href="'mailto:?subject=&body=Ciao, guarda la pizza che ho creato! Clicca qui '+encodeURIComponent(url)")
        .icon
        span Mail
      b-button#sb-wa(:href="'https://api.whatsapp.com/send/?phone=&text=Ciao,+guarda+la+pizza+che+ho+creato!+Clicca+qui+'+encodeURIComponent(url)+'&app_absent=0'")
        .icon
        span WhatsApp
      b-button#sb-tg(:href="'https://t.me/share/url?url='+encodeURIComponent(url)+'&text=Ciao, guarda la pizza che ho creato!'")
        .icon
        span Telegram
</template>

<script>
export default {
  name: "ShareBoxModal",
  props: ["url"],
  data() {
    return {
      message: this.$props.url,
    };
  },
  methods: {
    on_copy(e) {
      alert("Hai copiato nella tastiera il link: " + e.text);
    },
    on_error(e) {
      alert("Errore nel copiare il testo nella tastiera. Copialo manualmente!");
      console.log(e);
    },
  },
};
</script>
