<template lang="pug">
  main
    header-red
    sidebar

    b-container
      b-alert(show variant="danger" v-if="!token")
        | Il link di conferma email non è valido
      #tabs-login.single(v-else)

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import { mapActions } from "vuex";

export default {
  name: "EmailConfirm",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    this.token = this.$route.query["token"];
    this.confirm_email(this.token).then((response) => {
      if (response) {
        this.$bvToast.toast("Sarai presto reindirizzato", {
          title: "Email confermata",
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        setTimeout(() => {
          window.location.href = "/account";
        }, 1000);
      } else {
        this.$bvToast.toast("Ricontrolla il link alla email", {
          title: "Email non confermata",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
      }
    });
  },
  methods: {
    ...mapActions("auth", ["confirm_email"]),
  },
};
</script>
