<template lang="pug">
  b-modal(
    ref="modal" hide-footer header-close-content=""
    id="modal-new-address"
    @hidden="hidden()"
  )
    h3(v-if="!form.id") Aggiungi indirizzo
    h3(v-else) Modifica indirizzo
    validation-observer(ref="observer" v-slot="{ handleSubmit }")
      b-form(@submit.stop.prevent="handleSubmit(new_address)")
        form-input(
          label="Nome" v-model="form.name" type="text"
          :model="form.name" name="name"
          :rules="{required: true}"
          invalid_text="Il campo è obbligatorio"
        )
        form-input(
          label="Cellulare" v-model="form.cellular" type="text"
          :model="form.cellular" name="cellular"
          :rules="{required: true}"
          invalid_text="Il campo è obbligatorio"
        )
        fieldset.form-group(v-if="!form.street")
          vue-google-autocomplete(
            id="map"
            classname="form-control"
            placeholder="indirizzo"
            @placechanged="get_address_data"
            v-if="!form.id"
          )
          vue-google-autocomplete(
            id="map"
            classname="form-control"
            :placeholder="serialize_address(form)"
            @placechanged="get_address_data"
            v-else
          )
        fieldset.form-group(v-else)
          form-input(
            label="Via" v-model="form.street" type="text"
            :model="form.street" name="street"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
          form-input(
            label="Città" v-model="form.city" type="text"
            :model="form.city" name="city"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
          form-input(
            label="Provincia" v-model="form.province" type="text"
            :model="form.province" name="province"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
          form-input(
            label="CAP" v-model="form.postcode" type="text"
            :model="form.postcode" name="postcode"
            :rules="{required: true}"
            invalid_text="Il campo è obbligatorio"
          )
        b-form-group._btn
          b-overlay(
            :show="loading" rounded spinnel-small
          )
            b-button(type="submit" variant="primary") Salva
</template>

<script>
import FormInput from "@/components/FormInput.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import { mapGetters } from "vuex";

export default {
  name: "NewAddress",
  props: ["form", "hidden"],
  components: {
    "form-input": FormInput,
    VueGoogleAutocomplete,
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    new_address() {
      if (!this.form["street"]) {
        return;
      }

      let action = "auth/addresses/new_address";

      if (this.form.id) action = "auth/addresses/edit_address";

      if (this.form["province"].toUpperCase() != "CT") {
        this.$bvToast.toast("Consegniamo solo su Catania al momento", {
          title: "Errore durante il salvataggio dell'indirizzo!",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      this.$store.dispatch(action, this.form).then((res) => {
        if (!(res.status == 201 || res.status == 200)) {
          if (!res.data["detail"]) {
            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            let details = res.data.detail;
            if (this.form.province.toUpperCase() != "CT")
              details = "Consegniamo solo su Catania al momento";

            this.$bvToast.toast(details, {
              title: "Errore durante il salvataggio dell'indirizzo!",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        } else {
          this.$bvToast.toast("Adesso lo troverai nella tua lista", {
            title: "Indirizzo salvato!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.$refs["modal"].hide();
        }
      });
    },
    get_address_data(data, result, id) {
      console.log(id);
      const form = { ...this.form };
      form.street = `${data.route} ${data.street_number ?? ""}`;
      form.city = data.locality;
      if (result.address_components[4].short_name.length == 2)
        form.province = result.address_components[4].short_name;
      form.postcode = data.postal_code;

      this.$set(this, "form", form);
    },
  },
};
</script>
