<template lang="pug">
  .home-slider-grid
    h4 Sei pronto a "conzare" la tua pizza?
    h5 tutti gli ingredienti che vuoi ad unico prezzo!

    slick#grid-slick(ref="slick" :options="slick_options")
      aside
        h3 +30 ingredienti
        img(src="@/assets/logotype.svg")
        p Ingredienti per tutti i gusti, preparati freschi ogni giorno e pronti
          | per essere scelti. Ora puoi davvero creare la tua pizza preferita: anche
          | Senza Glutine, Senza Lattosio, Vegetariano e Vegano. 
          b Sii te stesso.
      aside
        h3 Un unico prezzo
        img(src="@/assets/euros.svg")
        p Qualunque sia il condimento che scegli per la pizza o l’insalata,
          | pagherai un unico prezzo fino a 7 ingredienti. In questo modo puoi farla
          | come desideri, senza bisogno di una calcolatrice. Seleziona il formato
          | che fa per te, pizza tonda, maxi, teglia, a trancio e inizia. 
          b Anche on line.
      aside
        h3 Doppia cottura
        img(src="@/assets/slice.svg")
        p Come evitare che le pizze arrivino bollite nelle scatole di cartone? 
          | Con l’esclusivo metodo della doppia cottura! Il nostro impasto a tre
          | farine complete del germe del grano viene sfornato così morbido dentro e
          | croccante fuori, da impedire al corpo della pizza di afflosciarsi col
          | vapore. 
          b Provalo subito e dicci la tua!
</template>

<script>
import Slick from "vue-slick";

export default {
  name: "HomeSliderGrid",
  components: { Slick },
  data() {
    return {
      slick_options: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows: false,
              infinite: true,
              slidesToShow: 1,
              autoplay: true,
              autoplaySpeed: 2000,
            },
          },
        ],
      },
    };
  },
};
</script>
