<template lang="pug">
  b-row#pizza-cart
    b-col(cols="12")
      .details
        img.trash(
          src="@/assets/trash-red.png"
          alt="Rimuovi pizza"
          title="Rimuovi pizza"
          @click="remove_pizza_reward(pizza.item_id)"
        )
        h3 {{ pizza.pizza_name }}
    b-col(cols="12")
      .details
        p(v-html="to_list(pizza)")
        p Dimensione: {{ pizza.size_text }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PizzaCartReward",
  props: ["pizza"],
  computed: {
    ...mapGetters("ingredients", ["to_list"]),
  },
  methods: {
    ...mapActions("rewards", ["remove_pizza_reward"]),
  },
};
</script>
