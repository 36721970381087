<template>
  <i class="icon">
    <div>
      <img :src="'/i/' + i + '.svg'" />
    </div>
  </i>
</template>

<script>
export default {
  name: "Icon",
  props: ["i"],
};
</script>
<style lang="sass">
i.icon
  width: 40px
  height: 45px
  float: left
  margin: 10px 10px 10px 0
  display: grid
  div
    display: grid
    align-items: center
    min-width: 30px
    img
      vertical-align: baseline
      max-height: 40px
</style>
