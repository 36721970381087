<template lang="pug">
  div(v-if="images.length > 1")
    slick#slider-for(ref="slider-for" :options="slick_options_for")
      img#top(:src="image" alt="img" v-for="image in images")
    slick#slider-nav(ref="slider-nav" :options="slick_options_nav")
      img(:src="image" alt="img" v-for="image in images")
  div(v-else)
    slick
      img#top(:src="images[0]" alt="img")

</template>

<script>
import Slick from "vue-slick";
export default {
  name: "PizzaSlider",
  components: { Slick },
  props: ["image"],
  data() {
    return {
      slick_options_for: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: "#slider-nav",
      },
      slick_options_nav: {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: "#slider-for",
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
      },
    };
  },
  computed: {
    images: function () {
      return this.$store.getters["pizzas/images"](this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/slick-carousel/slick/slick.css";
</style>
