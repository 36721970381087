<template lang="pug">
  validation-observer(
    ref="observer"
    v-slot="{ handleSubmit }"
  )
    section
      h4 Riepilogo ordine
      .pizza-row(v-for="item in items.pizza" :key="JSON.stringify(item)")
        pizza-cart(
          :pizza="item"
          :size_options="size_options"
          :dough_options="dough_options"
        )

    section
      h4 Aggiungi
      b-row
        extfood-cart(
          :group="group"
          :items="extfood_items"
          v-for="group in extfood_groups"
          :key="group.id"
        )

    section#points-banner(v-if="points.active > 0 && !reward_id")
      h4 Attualmente hai raccolto 
        b {{ points.active.toFixed(2) }} punti conzate*
      b-button(variant="outline-light" href="/cart/rewards") Riscatta i premi
      span *puoi saltare questa fase e continuare ad accumulare sempre più punti.
      span **i punti che accumulerai con questo ordine saranno disponibili all'ordine successivo.
    section#points-reward-selected-banner(v-else-if="points.active > 0 && reward_id")
      h4 Premio {{ reward.name }}
      b-button(href="/cart/rewards") Modifica
      span(v-for="item in reward_pizzas[reward_id]")
        | {{ item.pizza_name }}, 
      span(
        v-for="item in Object.values(reward_extfoods)"
      )
        span(v-if="serialized_rewards[reward_id]")
          | {{ serialized_rewards[reward_id][item].name }}, 

</template>

<script>
import FormInput from "@/components/FormInput.vue";
import PizzaCart from "@/components/PizzaCart.vue";
import ExtfoodCart from "@/components/ExtfoodCart.vue";

import { mapGetters } from "vuex";

export default {
  name: "CartHome",
  components: {
    "form-input": FormInput,
    "pizza-cart": PizzaCart,
    "extfood-cart": ExtfoodCart,
  },
  data() {
    return {
      form: {
        address: {},
      },
      map_class: "form-control",
      size_options: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["is_logged"]),
    ...mapGetters("cart", ["items"]),
    ...mapGetters("pizzas", ["dough_options"]),
    ...mapGetters("extfood", {
      extfood_items: "get_grouped_items",
      extfood_groups: "get_groups",
    }),
    ...mapGetters("rewards", ["points", "serialized_rewards"]),
    ...mapGetters("rewards", {
      reward_extfoods: "my_extfoods",
      reward_id: "my_reward_id",
      reward_pizzas: "my_reward_pizzas",
      reward: "my_reward_object",
    }),
  },
  methods: {
    get_address_data(data, result, id) {
      console.log(id);
      this.form.address.street = `${data.route} ${data.street_number ?? ""}`;
      this.form.address.city = data.locality;
      if (result.address_components[4].short_name.length == 2)
        this.form.address.province = result.address_components[4].short_name;
      this.form.address.postcode = data.postal_code;

      const fa = this.form.address;
      if (!(fa.street && fa.city && fa.province && fa.postcode)) {
        this.map_class = "form-control is-invalid";
      } else {
        this.map_class = "form-control";
      }
    },
    handleLogin() {},
  },
  mounted() {
    this.$store.dispatch("pizzas/get_sizes").then((result) => {
      for (const x of result) {
        this.size_options.push({
          text: x.name,
          value: x.id,
        });
      }
    });

    this.$store.dispatch("extfood/grouped_items");

    if (this.is_logged) {
      if (this.reward_id) this.$store.dispatch("rewards/find", this.reward_id);
    }
  },
};
</script>
