<template lang="pug">
  header#main-header
    #big-loading(v-if="loading")
      b-overlay(:show="true" spinner-large)
    b-container
      b-row
        b-col
          .pizzeria-info
            a(
              :href="'https://www.google.com/maps/place/' + info.address"
              rel="external" target="_blank"
            )
              p {{ info.address }}
            .numbers
              a.wa(:href="'tel:' + info.whatsapp")
                p {{ info.whatsapp }}
              a.email(:href="'mailto:' + info.email")
                p {{ info.email }}
          #hamburger(@click="handle_mobile_sidebar")
            img(src="@/assets/hamburger.svg" alt="Hamburger" v-if="!hamburger")
            img(src="@/assets/close-x.png" alt="Close x" v-else)
        b-col
          a(href="/" rel="home")
            img#logo(src="/logo.png" alt="Logo")
        b-col._l
          div
            a#cart(href="/cart")
              span.count {{ count }}
              img(src="@/assets/cart.png" alt="Cart")
            .name(v-if="user")
              a.account-icon(href="/account")
                img(src="@/assets/account.svg" alt="Account")
              b-button.logout(variant="dark" @click="logout") Log out
            div(v-else)
              b-button.inverted(@click="go_login()" variant="secondary") Accedi
              a.account-icon(@click="go_login()")
                img(src="@/assets/account.svg" alt="Account")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderRed",
  computed: {
    hamburger: function () {
      return this.$store.getters.show_sidebar;
    },
    loading: function () {
      return this.$store.getters.loading;
    },
    user: function () {
      return this.$store.getters["auth/get_me"];
    },
    ...mapGetters("cart", ["count"]),
    ...mapGetters(["info"]),
  },
  methods: {
    handle_mobile_sidebar() {
      this.$store.dispatch("toggle_sidebar");
    },
    logout() {
      this.$store.dispatch("auth/logout");
      window.location.reload();
    },
    go_login() {
      let path = "/login";
      path += "?ref=" + this.$route.path;

      window.location.href = path;
    },
  },
  mounted() {
    if (this.$store.getters["auth/is_logged"]) {
      this.$store.dispatch("auth/keep_access").then((access_token) => {
        // if access_token is null means that the credentials has been
        // changed
        if (access_token == null) {
          this.go_login();
        }
      });
    }
    this.$store.dispatch("get_info");
  },
};
</script>
