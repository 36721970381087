<template lang="pug">
  main
    header-red
    sidebar

    b-container
      b-alert(show variant="danger" v-if="!is_url_valid")
        | Il link di ripristino password non è valido
      #tabs-login.single(v-else)
        aside.active
          validation-observer(ref="observer" v-slot="{ handleSubmit }")
            b-form(@submit.stop.prevent="handleSubmit(onSubmit)")
              h2 Recupera password
              div(v-if="uid && token")
                form-input(
                  label="Nuova password" v-model="form.new_password1" type="password"
                  :model="form.new_password1" name="new_password1"
                  :rules="{required: true, min: 8}"
                  invalid_text="La password dev'essere lunga minimo 8 caratteri"
                )
                form-input(
                  label="Ripeti password" v-model="form.new_password2" type="password"
                  :model="form.new_password2" name="new_password2"
                  :rules="{required: true, confirmed: 'new_password1'}"
                  invalid_text="Le due password non coincidono"
                )
              div(v-else)
                form-input(
                  label="Email" v-model="form.email" type="email"
                  :model="form.email" name="email"
                  :rules="{required: true, email: true}"
                  invalid_text="Il campo non è una email valida"
                )
              b-form-group._btn
                b-button(type="submit" variant="primary") Avanti

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import FormInput from "@/components/FormInput.vue";

import { mapActions } from "vuex";

export default {
  name: "Forgot",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "form-input": FormInput,
  },
  props: ["uid", "token"],
  data() {
    return {
      form: {},
      password_type: false,
      is_url_valid: true,
    };
  },
  mounted() {
    if (this.$store.getters["auth/is_logged"]) {
      // Redirecting to login page we can ignore what logged_path is
      window.location.href = "/login";
    }

    if (!(this.$props.uid && this.$props.token)) {
      if (this.$route.fullPath.indexOf("/confirm/") > -1) {
        this.is_url_valid = false;
      }
    }
  },
  methods: {
    onSubmit() {
      this.reset_password({ props: this.$props, form: this.form }).then(
        (res) => {
          if (res.status != 200) {
            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            let text, title;
            if (this.uid && this.token) {
              text =
                "Password salvata, adesso puoi entrare con la nuova password";
              title = "Successo!";
            } else {
              text =
                "Un'email con un link è stata inviata alla tua casella di posta elettronica!";
              title = "Email inviata!";
            }

            this.$bvToast.toast(text, {
              title,
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        }
      );
    },
    ...mapActions("auth", ["reset_password"]),
  },
};
</script>
