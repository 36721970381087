import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";

import auth from "./modules/auth";
import ingredients from "./modules/ingredients";
import pizzas from "./modules/pizzas";
import orders from "./modules/orders";
import cart from "./modules/cart";
import extfood from "./modules/extfood";
import rewards from "./modules/rewards";
import promotions from "./modules/promotions";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_BACKEND_URL,
    show_sidebar: false,
    pizza_delivery: Vue.$cookies.get("pizza_delivery") || false,
    have_to_blur: false,
    loading: false,
    info: {},
  },
  getters: {
    show_sidebar: (state) => {
      return state.show_sidebar;
    },
    where_pizza_delivery: (state) => {
      return state.pizza_delivery;
    },
    have_to_blur: (state) => {
      return state.have_to_blur;
    },
    loading: (state) => {
      return state.loading;
    },
    api_endpoint: (state) => {
      return state.api;
    },
    info: (state) => {
      return state.info;
    },
  },
  mutations: {
    toggle_sidebar_state: (state) => {
      state.show_sidebar = !state.show_sidebar;
    },
    pizza_delivery_state: (state, value) => {
      Vue.$cookies.set("pizza_delivery", value, 60 * 60 * 20); // 20 hours and expire
      state.pizza_delivery = value;
    },
    change_blur_state: (state) => {
      state.have_to_blur = !state.have_to_blur;
    },
    loading_status: (state, value) => {
      state.loading = value;
    },
    save_info: (state, infos) => {
      state.info = infos;
    },
  },
  actions: {
    async toggle_sidebar({ commit }) {
      commit("toggle_sidebar_state");
    },
    async set_pizza_delivery({ commit }, type) {
      commit("pizza_delivery_state", type);
    },
    async blur_status({ commit }) {
      commit("change_blur_state");
    },
    async toggle_loading({ commit }, value) {
      commit("loading_status", value);
    },
    async get_info({ commit, rootState }) {
      const api = rootState.api;
      fetch(`${api}/info/`).then(async (response) => {
        const data = await response.json();
        commit("save_info", data);
      });
    },
  },
  modules: {
    auth,
    ingredients,
    pizzas,
    orders,
    cart,
    extfood,
    rewards,
    promotions,
  },
});
