<template lang="pug">
  div
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CartPayConfirm",
  async mounted() {
    const token = this.$route.query["token"];
    const toast_error = {
      title: "Token non valido",
      variant: "danger",
      solid: true,
      toaster: "b-toaster-top-center",
    };
    const toast_error_msg =
      "Il token di pagamento PayPal inserito non è valido";

    if (!token) {
      this.$bvToast.toast(toast_error_msg, toast_error);
      setTimeout(() => {
        window.location.href = "/orders";
      }, 2000);
    } else {
      await this.paypal_capture(token)
        .then((response) => {
          window.location.href = "/orders/" + response.order;
        })
        .catch(() => {
          this.$bvToast.toast(toast_error_msg, toast_error);
          setTimeout(() => {
            window.location.href = "/orders";
          }, 2000);
        });
    }
  },
  methods: {
    ...mapActions("orders", ["paypal_capture"]),
  },
};
</script>
