<template lang="pug">
  main#account
    header-red
    sidebar(:mobileonly="true")

    pizzas-grid(:all="true" :auth="true")

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import PizzasGrid from "@/components/PizzasGrid.vue";

export default {
  name: "MyPizzaList",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "pizzas-grid": PizzasGrid,
  },
};
</script>
