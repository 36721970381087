<template lang="pug">
  main#rules
    header-red
    sidebar

    b-container
      h4.mb-30 Programma 
        b "Conza PARADE"

      p Per te amicizia significa condividere una buona pizza con i tuoi amici? Allora questo è il programma che fa per te! Conzatù è l’unica pizzeria al mondo che non ha clienti, ma una comunità: Conzatù siamo tutti noi, le nostre pizze, le tue meravigliose creazioni, le pizze condite da te e che portano il tuo nome.
      p.mb-30 Da oggi puoi condividerle, promuoverle sui social, e guadagnarci pure: sarai ripagato in termini di punti “Conzate” che ti permetteranno di ricevere sconti e gratis le pizze Conzatu da dividere con i tuoi amici!


      h4 Come funziona?
      p.mb-30 Ogni volta che qualcuno ordina dall’App, o dal sito web, una pizza creata da te, riceverai una “conzata” (un punto*): puoi così accumulare sempre più “conzate” fino a scalare la vetta della classifica e ottenere premi, pizze omaggio e promozioni.

      h4 Che aspetti? Inizia!
      b-button(href="/conzatu" variant="primary")
        | Crea la tua pizza

      em.disclaimer
        | *dopo aver raggiunto 100 punti, al posto di 1 punto ricevi 1,2 punti dal martedì al giovedì; superati i 250 punti ricevi 1,2 punti ogni giorno.

      .container-2
        h4.mb-30 Un dolce <strong>in omaggio</strong> spetta a te e ai tuoi amici quando faranno il loro primo ordine dall’App, <strong>ma è solo l'inizio!</strong>
        h4.mb-30 Ecco tutti gli altri premi:
        div
          h4 Conzatore ufficiale
          p 10 Punti (che da questo momento chiamiamo "Conzate")
          p entro 30 giorni: due pizze Conzatu gratis.

        div
          h4 Conzatore I CLASSE
          p 20 "Conzate" in un mese: 1 pizza + 1 pizza Conzatù omaggio.

        div
          h4 Maestro Conzatore
          p 30 "Conzate" in un mese: 2 Pizze Conzatù omaggio.

        div
          h4 Giudice della pizza
          p 100 "Conzate" entro l'anno solare: i tuoi punti varranno 1,2 dal martedì al giovedì + accesso al gruppo segreto dei soci di Conzatù.

        div
          h4 Affiliato
          p 250 "Conzate" entro l'anno solare: punti che varranno 1,2 tutti i giorni della settimana + spedizione pacco riservato ai soci e franchisee.

        div
          h4 Venerabile
          p 500 "Conzate" entro l'anno solare: medaglia + Pizza garanzia (se non ti piace te la rifacciamo senza se e senza ma).

      hr

      .disclaimer
        div
          p.t Durata del Programma
          p Il Programma ha validità annuale con termine al 31 dicembre dell'anno solare.

        div
          p.t Modalità di partecipazione
          p La partecipazione al Programma è subordinata alla registrazione all’APP Conzatù e alla registrazione delle pizze personalizzate sulla medesima piattaforma. Possono partecipare al Programma tutte le persone fisiche che abbiano compiuto 18 anni e che abbiano compilato sull’APP tutti i campi di registrazione. Pizze registrate e punti accumulati saranno visualizzabili sull’App medesima. I premi possono essere richiesti entro la scadenza del programma, salvo diverse indicazioni di Conzatù.

        div
          p.t Trattamento dei dati personali
          p I dati personali che saranno forniti all'atto della registrazione sull’APP, ai fini della partecipazione al Programma, verranno trattati nel rispetto della normativa europea e italiana in materia di protezione dei dati personali. 
            a(href="/tos") Link
            | .

        div
          p.t Normativa di riferimento e note legali
          p La presente promozione non costituisce manifestazione a premio ai sensi dell'art. 6, c. 1, lett. c) e lett. c-bis), del D.P.R. 26 ottobre 2001, n.430. Le controversie derivanti, connesse e/o originate dal presente Regolamento saranno sottoposte al tentativo di obbligatorio di conciliazione. In caso di insuccesso della conciliazione il Foro competente per le controversie è quello del luogo di residenza del cliente-consumatore, individuato nel rispetto dei criteri di competenza stabiliti dal codice di procedura civile.

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Rules",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
};
</script>
