<template lang="pug">
  div
    b-navbar(:class="{'show-mobile': sidebar, 'mobileonly': mobileonly}")
      b-navbar-nav
        b-nav-item(href="/" :class="{active: (page == 'Home')}") Home
        b-nav-item(href="/pizza" :class="{active: (page == 'Pizza')}") Pizze
        b-nav-item(href="/download" :class="{active: (page == 'DownloadApp')}")
          | Scarica l'app
        b-nav-item(href="/conzatu" :class="{active: (page == 'CreatePizza')}")
          | Conzatù
        b-nav-item(href="/live" :class="{active: (page == 'Live')}")
          | In diretta
        b-nav-item(href="http://www.pizzafake.com/") Blog

        .footer
          .btns
            b-button(variant="light" href="/login" v-if="!is_logged") Login
            b-button(variant="light" @click="logout" v-else) Log out

          b Contatti
          span
            a(
              :href="'https://www.google.com/maps/place/' + info.address"
              rel="external" target="_blank"
            )
              | {{ info.address }}
          span
            a.g.wa(:href="'tel:' + info.whatsapp") {{ info.whatsapp }}
          span
            a.g.email(:href="'mailto:' + info.email") {{ info.email }}
          b Social
          .icons
            a(:href="info.facebook" target="_blank")
              img(src="@/assets/fb-circle.svg" alt="fb")
            a(:href="info.instagram" target="_blank")
              img(src="@/assets/ig-circle.svg" alt="ig")

    resend-email-verification-banner(v-if="!has_verified_email")

</template>

<script>
import { mapGetters } from "vuex";

import ResendEmailVerificationBanner from "@/components/ResendEmailVerificationBanner.vue";

export default {
  name: "Sidebar",
  props: ["page", "mobileonly"],
  components: {
    "resend-email-verification-banner": ResendEmailVerificationBanner,
  },
  computed: {
    sidebar: function () {
      return this.$store.getters.show_sidebar;
    },
    ...mapGetters("auth", ["is_logged", "has_verified_email"]),
    ...mapGetters(["info"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      window.location.reload();
    },
  },
};
</script>
