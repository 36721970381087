import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Privacy from "@/views/Privacy.vue";
import Tos from "@/views/Tos.vue";
// import PrivacyNewsletter from "@/views/PrivacyNewsletter.vue";
import Forgot from "@/views/Forgot.vue";
import EmailConfirm from "@/views/EmailConfirm.vue";
import Rules from "@/views/Rules.vue";
import Page404 from "@/views/Page404.vue";
import PizzaList from "@/views/PizzaList.vue";
import PizzaDetails from "@/views/user/PizzaDetails.vue";
import DownloadApp from "@/views/DownloadApp.vue";
import CreatePizza from "@/views/CreatePizza.vue";
import LivePage from "@/views/LivePage.vue";
import Franchising from "@/views/Franchising.vue";
import Cart from "@/views/cart/Cart.vue";
import CartHome from "@/views/cart/CartHome.vue";
import CartRewards from "@/views/cart/CartRewards.vue";
import CartShipping from "@/views/cart/CartShipping.vue";
import CartPay from "@/views/cart/CartPay.vue";
import CartPayConfirm from "@/views/cart/CartPayConfirm.vue";
import Feedback from "@/views/Feedback.vue";
import Login from "@/views/Login.vue";

import userRoutes from "./user";
import loginBeforeEnter from "./loginBeforeEnter";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Page404",
    component: Page404,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/password-reset(/confirm/)?:uid?/:token?",
    name: "Forgot",
    component: Forgot,
    props: true,
  },
  {
    path: "/email-confirm",
    name: "EmailConfirm",
    component: EmailConfirm,
    props: true,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/tos",
    name: "Tos",
    component: Tos,
  },
  // {
  //   path: "/privacy-newsletter",
  //   name: "PrivacyNewsletter",
  //   component: PrivacyNewsletter,
  // },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/pizza",
    name: "PizzaList",
    component: PizzaList,
  },
  {
    path: "/pizza/:pizza",
    name: "PizzaDetailsID",
    component: PizzaDetails,
    props: true,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/download",
    name: "DownloadApp",
    component: DownloadApp,
  },
  {
    path: "/conzatu",
    name: "CreatePizza",
    component: CreatePizza,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/live",
    name: "LivePage",
    component: LivePage,
  },
  {
    path: "/franchising",
    name: "Franchising",
    component: Franchising,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    children: [
      {
        path: "/cart",
        name: "CartHome",
        component: CartHome,
      },
      {
        path: "rewards",
        name: "CartRewards",
        component: CartRewards,
        beforeEnter: loginBeforeEnter,
      },
      {
        path: "shipping",
        name: "CartShipping",
        component: CartShipping,
        beforeEnter: loginBeforeEnter,
      },
      {
        path: "pay",
        name: "CartPay",
        component: CartPay,
        beforeEnter: loginBeforeEnter,
      },
      {
        path: "pay-confirm",
        name: "CartPayConfirm",
        component: CartPayConfirm,
      },
    ],
  },
  {
    path: "/feedback",
    name: "Feedback",
    props: true,
    component: Feedback,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.concat(userRoutes),
});

export default router;
