<template lang="pug">
  b-container
    b-alert.send-email-verification(show variant="warning")
      h3.alert-heading La tua email non è confermata
      hr
      p.mb-0 Se non confermi prima la tua email non potrai creare nuovi ordini.
      p Se non trovi l'email inviata alla creazione del tuo account, clicca il bottone qui sotto.
      b-button(@click="send_email()") Re invia email
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ResendEmailVerificationBanner",
  methods: {
    ...mapActions("auth", ["resend_email_verification"]),
    send_email() {
      this.resend_email_verification().then(async (response) => {
        if (response == 200) {
          this.$bvToast.toast(
            "È stata inviata una email con il link per attivare l'account",
            {
              title: "Controlla la tua posta",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            }
          );
        } else {
          this.$bvToast.toast("Abbiamo riscontrato un errore, riprova", {
            title: "Email non inviata",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        }
      });
    },
  },
};
</script>
