<template lang="pug">
  main
    header-red
    sidebar

    b-container
      #pizza-details
        .header
          a.back(@click="goback" href="#")
            span torna indietro

    b-container#edit-usersettings(class="password")
      h3 Cambia la tua password
      validation-observer(ref="observer" v-slot="{ handleSubmit }")
        b-form(@submit.stop.prevent="handleSubmit(handleNewPassword)")
          b-row
            b-col(cols="12" md="4")
              h4 Password attuale
              form-input(
                label="" v-model="form.old_password" type="password"
                :model="form.old_password" name="old_password"
                :rules="{required: true}"
                invalid_text="Inserisci la tua attuale password"
              )
            b-col(cols="12" md="4")
              h4 Nuova password
              form-input(
                label="" v-model="form.new_password1" type="password"
                :model="form.new_password1" name="new_password1"
                :rules="{required: true, min: 8}"
                invalid_text="La password dev'essere lunga minimo 8 caratteri"
              )
            b-col(cols="12" md="4")
              h4 Ripeti nuova password
              form-input(
                label="" v-model="form.new_password2" type="password"
                :model="form.new_password2" name="new_password2"
                :rules="{required: true, confirmed: 'new_password1'}"
                invalid_text="Le due password non coincidono"
              )

          b-button.btn(variant="primary" type="submit")
            | Salva

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import FormInput from "@/components/FormInput.vue";

export default {
  name: "UserSettings",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "form-input": FormInput,
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    goback() {
      return window.history.back();
    },
    handleNewPassword() {
      this.$store.dispatch("auth/change_password", this.form).then((res) => {
        if (res.status != 200) {
          if (!res.data["detail"]) {
            for (const key of Object.keys(res.data)) {
              for (const message of res.data[key]) {
                this.$bvToast.toast(message, {
                  title: key,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center",
                });
              }
            }
          } else {
            this.$bvToast.toast(res.detail, {
              title: "Errore durante il salvataggio!",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          }
        } else {
          this.$bvToast.toast("Password salvata", {
            title: "Successo!",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
          this.form = {};
        }
      });
    },
  },
};
</script>
