<template lang="pug">
  main#account.points-page
    header-red
    sidebar(:mobileonly="true")

    b-container
      #pizza-details
        .header
          a.back(@click="goback" href="#")
            span torna indietro

    b-container
      b-row
        b-col(md="6" cols="12")
          b-row.head
            b-col.points-num
              h4 Punti conzate
              h2 {{ float_precision(points.total) }}
          b-row.mini
            b-col.points-num
              h4 Punti pizze acquistate
              h3 {{ float_precision(points.from_purchase) }}
            b-col.points-num
              h4 Punti pizze condivise
              h3 {{ float_precision(points.from_others) }}
          b-row.mini
            b-col.points-num
              h4 Punti usati
              h3 {{ float_precision(points.total - points.active) }}
            b-col.points-num
              h4 Punti attivi
              h3 {{ float_precision(points.active) }}

          b-row
            b-col
              a.rules-link(href="/rules") Leggi il regolamento

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import { mapGetters } from "vuex";

export default {
  name: "UserPoints",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  computed: {
    ...mapGetters("rewards", ["points"]),
  },
  methods: {
    goback() {
      return window.history.back();
    },
    float_precision(price) {
      if (!price) return 0;
      return price.toFixed(2);
    },
  },
  mounted() {
    this.$store.dispatch("rewards/get_my_points");
  },
};
</script>
