<template lang="pug">
  #select-pizza-delivery
    div
      h2 La tua pizza
      .btns
        b-button(variant="primary" @click="handlePizzaDelivery('take')")
          | La vieni a prendere
        //- b-button(variant="light" @click="handlePizzaDelivery('home')")
        //-   | Consegna a domicilio
      span.later(@click="handlePizzaDelivery('later')") Scegli dopo
</template>

<script>
export default {
  name: "PizzaDeliverySelect",
  methods: {
    handlePizzaDelivery(type) {
      this.$store.dispatch("set_pizza_delivery", type);
    },
  },
};
</script>
