const moment = require("moment");

export default {
  namespaced: true,
  state: {
    points: {},
    items: [],
    reward: {},
    reward_id: localStorage.getItem("reward_id"),
    reward_items: JSON.parse(localStorage.getItem("reward_items")),
    reward_extfoods: JSON.parse(localStorage.getItem("reward_extfoods")),
    pizza_to_select: JSON.parse(localStorage.getItem("pizza_to_select")),
    reward_pizzas: JSON.parse(localStorage.getItem("reward_pizzas")),
    serialized_rewards: {}
  },
  getters: {
    points: state => {
      return state.points;
    },
    extra_today_points: state => {
      const weekday = moment().format("ddd");

      if(!state.points.active) return 1;

      if (state.points.active >= 100) {
        if (state.points.active >= 250) {
          return 1.2;
        }

        if (weekday == "Tue" || weekday == "Wed" || weekday == "Thu")
          return 1.2;
      }

      return 1;
    },
    items: state => {
      return state.items;
    },
    first_order_rewards: state => {
      if (state.items) {
        return state.items.filter(item => item.cost == 0 && item.is_enabled);
      }
      return [];
    },
    available_rewards: (state, getters, rootState) => {
      if (rootState.auth.user) {
        const orders_count = rootState.auth.user.orders_count;
        if (orders_count == 0) {
          return getters.first_order_rewards;
        }

        if (state.items) {
          return state.items.filter(
            item =>
              item.cost > 0 &&
              item.cost <= state.points.active &&
              item.is_enabled
          );
        }
      }

      return [];
    },
    my_items: state => {
      return state.reward_items || {};
    },
    my_extfoods: state => {
      return state.reward_extfoods || {};
    },
    my_reward_id: state => {
      return state.reward_id;
    },
    my_reward_object: state => {
      return state.reward;
    },
    pizza_to_select: state => {
      return state.pizza_to_select;
    },
    my_reward_pizzas: state => {
      return state.reward_pizzas || {};
    },
    group_extfood: () => extfoods => {
      const extfood_list = {};
      const extfood_ids = [];

      for (const extfood of extfoods) {
        // Add the extood group id in the list of ids already saved
        const egi = extfood.group.id;
        if (extfood_ids.indexOf(egi) < 0) {
          extfood_ids.push(egi);
          extfood_list[egi] = extfood.group;
          extfood_list[egi]["ids"] = [];
        }

        extfood_list[egi]["ids"].push(extfood.id);
      }

      return Object.values(extfood_list);
    },
    serialized_rewards: state => {
      return state.serialized_rewards;
    }
  },
  mutations: {
    save_points: (state, points) => {
      state.points = points;
    },
    save_items: (state, rewards) => {
      state.items = rewards.results;
    },
    save_current_reward_object: (state, reward) => {
      state.reward = reward;
    },
    save_current_reward: (state, reward_id) => {
      state.reward_id = reward_id;
      localStorage.setItem("reward_id", reward_id);
    },
    save_current_reward_items: (state, items) => {
      state.reward_items = items;
      localStorage.setItem("reward_items", JSON.stringify(items));
    },
    save_current_reward_extfoods: (state, extfoods) => {
      state.reward_extfoods = extfoods;
      localStorage.setItem("reward_extfoods", JSON.stringify(extfoods));
    },
    save_current_pizza_to_select: (state, pizza) => {
      state.pizza_to_select = pizza;
      localStorage.setItem("pizza_to_select", JSON.stringify(pizza));
    },
    save_reward_pizzas: (state, pizzas) => {
      state.reward_pizzas = pizzas;
      localStorage.setItem("reward_pizzas", JSON.stringify(pizzas));
    },
    add_reward_pizza: (state, pizza) => {
      let pizzas = state.reward_pizzas;
      const reward = state.reward_id;
      if (pizzas) {
        if (pizzas[reward]) {
          pizzas[reward].push(pizza);
        } else {
          pizzas[reward] = [pizza];
        }
      } else {
        pizzas = {};
        pizzas[reward] = [pizza];
      }

      state.reward_pizzas = pizzas;
      localStorage.setItem("reward_pizzas", JSON.stringify(pizzas));
    },
    remove_pizza_reward: (state, item_id) => {
      let index_to_delete = -1;
      const iter = state.reward_pizzas[state.reward_id];
      for (const i in iter) {
        if (iter[i].item_id == item_id) {
          index_to_delete = i;
          break;
        }
      }

      if (index_to_delete >= 0) iter.splice(index_to_delete, 1);

      const reward_id = state.reward_id;
      state.reward_pizzas = {};
      state.reward_pizzas[reward_id] = iter;
      localStorage.setItem(
        "reward_pizzas",
        JSON.stringify(state.reward_pizzas)
      );
    },
    serialize_my_rewards: (state, rewards) => {
      const items = {};
      for (const reward of rewards) {
        items[reward.id] = {};
        for (const extfood of reward.extfood) {
          for (const item of extfood.group.items) {
            items[reward.id][item.id] = item;
          }
        }
      }

      state.serialized_rewards = items;
    }
  },
  actions: {
    get_my_points({ commit, rootGetters, rootState }) {
      fetch(`${rootState.api}/points/mine/`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      }).then(async response => {
        commit("save_points", await response.json());
      });
    },
    get_rewards({ commit, rootGetters, rootState }) {
      let rewards;
      fetch(`${rootState.api}/rewards/?limit=1000`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      }).then(async response => {
        rewards = await response.json();
        commit("save_items", rewards);
        commit("serialize_my_rewards", rewards.results);
      });
    },
    find({ commit, rootGetters, rootState }, reward_id) {
      fetch(`${rootState.api}/rewards/${reward_id}/`, {
        headers: {
          Authorization: `Bearer ${rootGetters["auth/access_token"]}`
        }
      }).then(async response => {
        commit("save_current_reward_object", await response.json());
      });
    },
    select_reward_id({ commit }, reward_id) {
      commit("save_current_reward", reward_id);
    },
    select_reward({ commit }, data) {
      // `data` is an object like `{reward_id: id, items: object}`
      commit("save_current_reward", data.reward_id);
      commit("save_current_reward_items", data.items);
    },
    start_pizza_select({ commit, rootGetters }, pizza) {
      // `pizza` is {id: <id>, pizza_size: <size>}

      // Clear all pizzas not in rewards
      const pizzas = rootGetters["rewards/my_reward_pizzas"];
      // it could be `null`
      if (pizzas) {
        const reward_id = rootGetters["rewards/my_reward_id"];
        for (const reward of Object.keys(pizzas)) {
          if (reward != reward_id) {
            delete pizzas[reward];
          }
        }
      }

      commit("save_reward_pizzas", pizzas);
      commit("save_current_pizza_to_select", pizza);
    },
    save_reward_extfoods({ commit }, extfoods) {
      commit("save_current_reward_extfoods", extfoods);
    },
    add_pizza({ commit }, item) {
      commit("add_reward_pizza", item);
      commit("save_current_pizza_to_select", {});
    },
    remove_pizza_reward({ commit }, item_id) {
      commit("remove_pizza_reward", item_id);
    },
    rewards_clear() {
      localStorage.removeItem("reward_id");
      localStorage.removeItem("reward_items");
      localStorage.removeItem("reward_extfoods");
      localStorage.removeItem("pizza_to_select");
      localStorage.removeItem("reward_pizzas");
    }
  }
};
