import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import { BootstrapVue } from "bootstrap-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";

import { required, min, max, email, confirmed } from "vee-validate/dist/rules";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_RATE,
  environment: process.env.VUE_APP_SENTRY_ENV,
});

extend("delivery", function (value) {
  const moment = require("moment");
  const hour = moment(value, "HH:mm").format("HH");
  const minutes = moment(value, "HH:mm").format("mm");

  if (hour == 18 && minutes >= 20) return true;
  else if (hour >= 19 && hour <= 23) return true;

  return false;
});

extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("confirmed", confirmed);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(require("vue-moment"));

Vue.directive("scroll", {
  inserted: function (el, binding) {
    const onScrollCallback = binding.value;
    window.addEventListener("scroll", () => onScrollCallback());
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
