<template lang="pug">
  main#cart
    header-red
    sidebar

    b-container
      #pizza-details
        .header
          a.back(@click="goback" href="#")
            span torna indietro

      div(v-if="items.pizza.length > 0")
        router-view(ref="cart")
        section#bottom(v-if="name != 'CartRewards'")
          h3 Punti conzate 
            strong {{ points * extra_today_points }}

          h3(v-if="delivery == 'home'")
            | Consegna a domicilio 
            strong(v-if="gratis_ship()") GRATIS
            strong(v-else) €{{ orders_settings.delivery_price }}

          h3 Totale €{{ total }}

          b-button(
            variant="primary"
            type="submit"
            @click="next_step()"
            :disabled="!orders_settings.are_orders_enabled"
          )
            span(v-if="name == 'CartPay'") Paga adesso
            span(v-else) Continua
          b-button(
            variant="secondary"
            type="submit"
            @click="next_step(true)"
            v-if="name == 'CartPay'"
            style="margin-top: 20px"
            :disabled="!orders_settings.are_orders_enabled"
          ) Paga alla consegna
          span.d-block.text-not-available(
            v-if="!orders_settings.are_orders_enabled"
            style="text-align: center"
          )
            | Attualmente il negozio è chiuso
      section#bottom(v-else-if="name != 'CartPayConfirm'")
        h2 Il carrello è vuoto
        p Vai <a href="/pizza">qui</a> per aggiungere una pizza al carrello!
      div(v-else)
        router-view(ref="cart")

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Cart",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    ...mapGetters("cart", ["items", "points", "total", "delivery"]),
    ...mapGetters("rewards", { reward_id: "my_reward_id" }),
    ...mapGetters("rewards", ["extra_today_points"]),
    ...mapGetters("orders", ["orders_settings"]),
    ...mapGetters("auth", ["is_logged"]),
  },
  async mounted() {
    this.name = this.$route.name;
    await this.$store.dispatch("pizzas/get_doughs");
    this.$store.dispatch("orders/get_settings");
    if (this.is_logged) {
      this.$store.dispatch("rewards/get_my_points");
      this.$store.dispatch("rewards/get_rewards");
    }
  },
  methods: {
    goback() {
      if (this.name == "CartRewards" && this.reward_id) {
        this.rewards_clear();
        return window.location.reload();
      }
      return window.history.back();
    },
    next_step(flag) {
      if (this.name == "CartHome") {
        window.location.href = "/cart/shipping";
      } else if (this.name == "CartShipping") {
        this.$refs.cart.save_step();
      } else if (this.name == "CartPay") {
        this.$refs.cart.new_order(flag ?? false);
      }
    },
    gratis_ship() {
      return (
        this.total >= parseFloat(this.orders_settings.delivery_price_threshold)
      );
    },
    ...mapActions("rewards", ["rewards_clear"]),
  },
};
</script>
