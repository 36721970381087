import Account from "@/views/user/Home.vue";
import UserPage from "@/views/user/UserPage.vue";
import UserPoints from "@/views/user/UserPoints.vue";
import MyPizzaList from "@/views/user/PizzaList.vue";
import Orders from "@/views/user/order/Orders.vue";
import OrdersList from "@/views/user/order/OrdersList.vue";
import OrderSingle from "@/views/user/order/OrderSingle.vue";
import PizzaDetails from "@/views/user/PizzaDetails.vue";
import UserSettings from "@/views/user/UserSettings.vue";
import PasswordSettings from "@/views/user/PasswordSettings.vue";

import loginBeforeEnter from "./loginBeforeEnter";

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/account/pizza",
    name: "MyPizzaList",
    component: MyPizzaList,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    children: [
      {
        path: "/orders",
        name: "OrdersList",
        component: OrdersList,
        beforeEnter: loginBeforeEnter,
      },
      {
        path: ":id",
        name: "OrderSingle",
        component: OrderSingle,
        beforeEnter: loginBeforeEnter,
        props: true,
      },
    ],
  },
  {
    path: "/u/:username",
    name: "UserPage",
    component: UserPage,
    props: true,
  },
  {
    path: "/u/:username/:pizza",
    name: "PizzaDetails",
    component: PizzaDetails,
    props: true,
  },
  {
    path: "/points",
    name: "UserPoints",
    component: UserPoints,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: UserSettings,
    beforeEnter: loginBeforeEnter,
  },
  {
    path: "/settings/password",
    name: "PasswordSettings",
    component: PasswordSettings,
    beforeEnter: loginBeforeEnter,
  },
];

export default routes.map((route) => {
  return { ...route };
});
