export default {
  namespaced: true,
  state: {
    items: [],
    groups: []
  },
  getters: {
    get_grouped_items: state => {
      const groups = state.groups.map(x => x.id);

      let data = {};
      for (const i of groups) {
        data[i] = state.items.filter(x => x.group == i);
      }

      return data;
    },
    get_groups: state => {
      return state.groups;
    }
  },
  mutations: {
    save_items: (state, data) => {
      state.items = data["results"];
    },
    save_groups: (state, data) => {
      state.groups = data["results"];
    }
  },
  actions: {
    async grouped_items({ commit, rootState }) {
      fetch(`${rootState.api}/extfood/groups/?limit=1000`).then(
        async response => {
          commit("save_groups", await response.json());
        }
      );

      await fetch(`${rootState.api}/extfood/items/?limit=1000`).then(
        async response => {
          commit("save_items", await response.json());
        }
      );
    }
  }
};
