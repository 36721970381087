<template lang="pug">
  slick#homeslick(ref="slick" :options="slick_options")
    section.home-slider(
      v-for="slide in slider"
      :key="slider.id"
      :style="{ 'background-image': 'url(' + slide.photo + ')' }"
    )
      div
        .image
        .disclaimer
          h1 {{ slide.title }}
          .text {{ slide.description }}
          b-button(:href="slide.button_link" variant="primary") {{ slide.button_text }}
</template>

<script>
import Slick from "vue-slick";
import { mapGetters } from "vuex";

export default {
  name: "HomeSlider",
  components: { Slick },
  data() {
    return {
      slick_options: {
        autoplay: true,
        autoplaySpeed: 3000,
      },
    };
  },
  computed: {
    ...mapGetters("promotions", ["slider"]),
  },
  mounted() {
    this.$store.dispatch("promotions/get_slider");
  },
  watch: {
    slider: function () {
      let currIndex = this.$refs.slick.currentSlide();

      this.$refs.slick.destroy();
      this.$nextTick(() => {
        this.$refs.slick.create();
        this.$refs.slick.goTo(currIndex, true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/slick-carousel/slick/slick.css";
</style>
