<template lang="pug">
  main#account
    header-red
    sidebar(:mobileonly="true")

    b-container
      #pizza-details
        .header
          a.back(@click="goback" href="#")
            span torna indietro

    router-view

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

export default {
  name: "Orders",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,
  },
  methods: {
    goback() {
      return window.history.back();
    },
  },
};
</script>
