<template lang="pug">
  main
    header-red
    sidebar(page="Home")

    //- section(v-if="!pizza_delivery")
    //-  select-pizza-delivery

    section#first-home
      home-slider

    section#second-home
      b-container
        div.head
          h2 Le più scelte
          b-button(href="/pizza" variant="primary") Vedi tutte

        pizza-slider

    section#slider-grid
      b-container
        home-slider-grid

    section#share-your-pizza
      b-container
        h4 Condividi la tua pizza e guadagni "Conzate"
        .d-flex
          aside
            p Puoi salvare con il TUO NOME la pizza fatta da te e promuoverla tra gli
              | amici. Se la scelgono anche loro, ci guadagnate entrambi.
            p E in ogni caso, al tuo primo ordine, scegli il tuo omaggio!
            p Scarica l’app e diventa subito <b>"Conzatore ufficiale"</b>. Scopri di più!
          aside
            h3 Download
            .d-flex
              a(href="https://apps.apple.com/it/app/conzatu/id1600270492" target="_blank")
                img(src="@/assets/app-ios.png" alt="iOS")
              a(href="https://play.google.com/store/apps/details?id=com.liberotratto.conzatu" target="_blank")
                img(src="@/assets/app-android.png" alt="Android")

    b-container
      boxes-3(big)

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import HomeSlider from "@/components/HomeSlider.vue";
import HomeSliderGrid from "@/components/HomeSliderGrid.vue";
import PizzaSlider from "@/components/PizzaSlider.vue";
import PizzaDeliverySelect from "@/components/PizzaDeliverySelect.vue";
import Boxes3 from "@/components/Boxes3.vue";

export default {
  name: "Home",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "home-slider": HomeSlider,
    "home-slider-grid": HomeSliderGrid,
    "pizza-slider": PizzaSlider,
    "select-pizza-delivery": PizzaDeliverySelect,
    "boxes-3": Boxes3,
  },
  computed: {
    pizza_delivery: function () {
      return this.$store.getters.where_pizza_delivery;
    },
  },
};
</script>
