<template lang="pug">
  main#live
    header-red
    sidebar(page="Live")

    b-container
      #live-stream
        iframe(
          v-if="live_stream != ''"
          width="100%" height="800"
          :src="live_stream"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        )
        h1(v-else) Diretta offline

    b-container
      .order
        live-row(
          v-for="order, i in orders" :key="order.id" :pizza="order"
          :index="i"
        )

    b-container
      hr
      boxes-3

    footer-red
</template>

<script>
import HeaderRed from "@/components/design/HeaderRed.vue";
import Sidebar from "@/components/design/Sidebar.vue";
import FooterRed from "@/components/design/FooterRed.vue";

import LiveRow from "@/components/LiveRow.vue";
import Boxes3 from "@/components/Boxes3.vue";

import { mapGetters } from "vuex";

export default {
  name: "LivePage",
  components: {
    "header-red": HeaderRed,
    sidebar: Sidebar,
    "footer-red": FooterRed,

    "live-row": LiveRow,
    "boxes-3": Boxes3,
  },
  computed: {
    orders: function () {
      return this.$store.getters["orders/get_live_orders"];
    },
    ...mapGetters("orders", ["live_stream"]),
  },
  mounted() {
    this.$store.dispatch("orders/lives");
    this.$store.dispatch("orders/get_live_page");
  },
};
</script>
