<template lang="pug">
  section.reward
    h2.gift(v-if="get_me && get_me.orders_count == 0")
      | Scegli l'omaggio per il tuo primo ordine!
    h2(v-else)
      b Programma punti Pizza Connection - 
      | puoi selezionare solo 1 premio 
      span <b>{{ points.active }}</b> Conzate totali

    b-form-group
      b-row.reward-row(
        v-for="reward in available_rewards"
        :key="reward.id"
        v-if="reward.is_enabled && (!reward_id || reward_id == reward.id)"
      )
        b-col(cols="12")
          h3.reward-title
            b-form-radio(@change="select_reward_id(reward.id)" :checked="reward_id" :value="reward.id")
              b {{ reward.name }} 
              | - {{ reward.description }} - costo 
              b {{ reward.cost }} 
              | conzate
        b-col(cols="12" v-if="reward_id == reward.id")
          b-row
            b-col.extfood(v-for="extfood in group_extfood(reward.extfood)" :key="extfood.id" md="4")
              h3 {{ extfood.name }} - 
                span.qty x{{ extfood.ids.length }}
              b-card
                b-form-checkbox-group(
                  v-for="item in extfood.items"
                  :key="item.id"
                  v-model="reward_selected[extfood.id]"
                  @change="select_extfood(reward.id, extfood.id, extfood.ids.length)"
                )
                  b-form-checkbox(
                    :value="item.id"
                  ) {{ item.name }}
                  div.qta.horizontal.d-flex(
                    v-if="extfood.ids.length > 1 &&\
                      reward_selected[extfood.id] &&\
                      reward_selected[extfood.id].indexOf(item.id) > -1"
                    style="margin-bottom: 20px"
                  )
                    b-button(variant="secondary" @click="add_qta_rew_ext(-1, extfood.id, item.id, extfood.ids.length, reward.id)") -
                    b-form-input(
                      type="number" min="1" max="99" name="quantity"
                      :value="nums_of_reward_extfood_item(extfood.id, item.id)"
                    )
                    b-button(variant="secondary" @click="add_qta_rew_ext(1, extfood.id, item.id, extfood.ids.length, reward.id)") +

            b-col(
              md="4"
              v-for="i, index in reward.pizzas"
              :key="index+'_'+i.id"
            )
              .pizza-row(v-if="reward_pizzas[reward_id] && pizza_reward_is_present(i.id)")
                pizza-cart-reward(:pizza="pizza_reward_is_present(i.id)")
              b-button(
                @click="select_pizza_reward(reward.id, i)"
                v-else
              )
                | Seleziona pizza {{ size_options[i.pizza_size] }}

            b-col(md="4")
              b-button(variant="primary" @click="check_rewards_are_selected(group_extfood(reward.extfood))")
                | Continua
</template>

<script>
import PizzaCartReward from "@/components/PizzaCartReward.vue";

import { mapGetters, mapActions } from "vuex";

const zip = require("lodash/zip");
const flatten = require("lodash/flatten");

export default {
  name: "CartRewards",
  components: {
    "pizza-cart-reward": PizzaCartReward,
  },
  data() {
    return {
      size_options: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["get_me"]),
    ...mapGetters("rewards", ["available_rewards", "points", "group_extfood"]),
    ...mapGetters("rewards", {
      reward_selected: "my_items",
      reward_id: "my_reward_id",
      reward_pizzas: "my_reward_pizzas",
    }),
  },
  mounted() {
    this.$store.dispatch("rewards/get_my_points");
    this.$store.dispatch("rewards/get_rewards");
    this.$store.dispatch("pizzas/get_sizes").then((result) => {
      for (const x of result) {
        this.$set(this.size_options, x.id, x.name);
      }
    });
  },
  methods: {
    pizza_reward_is_present(item_id) {
      if (!this.reward_id) return null;

      for (const item of this.reward_pizzas[this.reward_id]) {
        if (item.item_id == item_id) {
          return item;
        }
      }

      return null;
    },
    nums_of_reward_extfood_item(extfood_id, item_id) {
      // Prints the number of `item_id` inside `reward_selected[extfood_id]`
      return this.reward_selected[extfood_id].filter((x) => x == item_id)
        .length;
    },
    add_qta_rew_ext(incr, extfood, key, extfood_lengths, reward_id) {
      // Add a duplicate `key` inside the `reward_selected[extfood]` if `incr`
      // is 1, else remove one `key` from there.
      const extfood_list = this.reward_selected[extfood];
      if (incr == 1) {
        extfood_list.push(key);
      } else {
        const index_to_remove = extfood_list.indexOf(key);
        if (index_to_remove > -1) extfood_list.splice(index_to_remove, 1);
      }

      this.$set(this.reward_selected, extfood, extfood_list);
      this.select_extfood(reward_id, extfood, extfood_lengths);
    },
    select_extfood(reward_id, extfood_id, extfoods_length) {
      // If rewards selected are more than extfoods_length (number of ids of
      // reward_extood) then remove the first element and append the last one
      // checked
      if (this.reward_selected[extfood_id].length > extfoods_length) {
        this.reward_selected[extfood_id].shift();
      }
      this.select_reward({ reward_id, items: this.reward_selected });
    },
    select_pizza_reward(reward_id, pizza) {
      this.start_pizza_select(pizza);

      window.location.href = "/pizza";
    },
    check_rewards_are_selected(extfoods) {
      const reward_extfoods = {};
      const reward_extfoods_list = [];

      // Create a list of list made by [reward_extfood_id, item_id]
      // Thanks to `zip` the matching is random, cause each reward_extfood_id
      // matches with someone of item selected in `reward_selected`.
      // The result will be in the dictionary `reward_extfoods` where `key` is
      // the reward_extfood_id and `value` is the item_id.
      for (const extfood of extfoods) {
        if (
          this.reward_selected[extfood.id] &&
          this.reward_selected[extfood.id].length == extfood.ids.length
        ) {
          reward_extfoods_list.push(
            zip(extfood.ids, this.reward_selected[extfood.id])
          );
        }
      }

      for (const reward_extfood_pair of reward_extfoods_list) {
        let flatten_pairs = flatten(reward_extfood_pair);
        for (let i = 0; i < flatten_pairs.length; i += 2) {
          const key = flatten_pairs[i];
          const value = flatten_pairs[i + 1];
          reward_extfoods[key] = value;
        }
      }

      if (!this.reward_id) {
        window.location.href = "/cart/shipping";
        return;
      }

      let extfood_reward_length;
      let pizza_reward_length;
      for (const reward of this.available_rewards) {
        if (reward.id == this.reward_id) {
          extfood_reward_length = reward.extfood.length;
          pizza_reward_length = reward.pizzas.length;
          break;
        }
      }
      let extfood_length = 0;
      for (const extfood of Object.values(reward_extfoods)) {
        if (extfood) {
          extfood_length++;
        }
      }

      let pizza_length = 0;
      if (this.reward_pizzas[this.reward_id])
        pizza_length = Object.keys(this.reward_pizzas[this.reward_id]).length;

      let to_return = false;
      if (pizza_length != pizza_reward_length) {
        this.$bvToast.toast(
          "Devi selezionare tutte le pizze omaggio del tuo premio",
          {
            title: "Scelta premio incompleta!",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          }
        );
        to_return = true;
      }

      if (extfood_length != extfood_reward_length) {
        this.$bvToast.toast(
          "Devi selezionare tutti gli omaggi dei gruppi disponibili",
          {
            title: "Scelta premio incompleta!",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          }
        );
        to_return = true;
      }

      if (to_return) return;

      // If good, we can set the new extfoods item in storage
      this.save_reward_extfoods(reward_extfoods);

      window.location.href = "/cart/shipping";
    },
    ...mapActions("rewards", [
      "select_reward",
      "select_reward_id",
      "start_pizza_select",
      "save_reward_extfoods",
    ]),
  },
};
</script>
