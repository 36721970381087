import store from "@/store";

const loginBeforeEnter = function(to, from, next) {
  if (store.getters["auth/is_logged"]) {
    next();
  } else {
    next({ name: "Login", query: { ref: to["fullPath"] } });
  }
};

export default loginBeforeEnter;
